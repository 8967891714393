<template>
  <v-container
    fluid
    class="light-blue lighten-5 px-1 pb-16"
  >
    <!-------------------------------------------STEPPER--------------------------------------->
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-stepper
            v-model="e1"
            elevation="0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                About you
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                Coverage
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="e1 > 3"
                step="3"
              >
                Lifestyle
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="e1 > 4"
                step="4"
              >
                Health
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="e1 > 5"
                step="5"
              >
                Documents
              </v-stepper-step>
              <v-divider class="primary" />
              <v-stepper-step
                :complete="e1 > 6"
                step="6"
              >
                Review
              </v-stepper-step>
              <v-divider class="primary" />

              <v-stepper-step step="7">
                Dev
              </v-stepper-step>
            </v-stepper-header>
            <!----------------------- STEP 1: ABOUT YOU/NOTICES------------------------------------>
            <v-stepper-items>
              <v-stepper-content step="1">
                <validation-observer
                  v-slot="{ invalid }"
                  ref="observer"
                >
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Let's get started
                    </div>
                    <!------------------------------------FORMS DIALOG------------------------------------>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                        class="d-flex flex-row-reverse"
                      >
                        <v-dialog
                          v-model="dialogack"
                          persistent
                          max-width="900"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="blanketbutton"
                              dark
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            >
                              Attestations and Notices
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="text-h3 ma-2 font-weight-bold blanketbutton--text">
                              Legal Notices
                            </v-card-title>
                            <v-progress-linear
                              :active="pdfsloading"
                              :indeterminate="pdfsloading"
                              absolute
                              color="primary"
                            />
                            <v-card-text
                              v-if="!pdfsloading"
                              class="headline-1 ma-4 pa-4"
                            >
                              The documents below authorize us to review your life insurance application and request and obtain additional information that will help us determine your coverage. Please be sure to open and review each document, save or print it and then if you understand and agree to the terms, check the box to the left to authorize us to proceed. If you do not agree to all the terms, conditions and disclosure consent, do not check the box, close this application and contact your insurance professional.
                            </v-card-text>
                            <v-col
                              v-if="!pdfsloading"
                            >
                              <v-row
                                v-for="item in noticeListFiles"
                                :key="item.name"
                                class="justify-center"
                              >
                                <v-row class="ma-2 pa-2">
                                  <v-col
                                    cols="6"
                                    sm="6"
                                    md="9"
                                  >
                                    <v-card-text class="headline-1 ma-4 pa-4 blanketcolor--text">
                                      {{ item.name }}
                                      <a
                                        :href="item.url"
                                        target="_blank"
                                      >  View Document</a>
                                    </v-card-text>
                                    <v-checkbox
                                      v-model="item.ack"
                                      :label="'I have read ' + item.name"
                                      color="blanketbutton"
                                      value="true"
                                      hide-details
                                      @change="ackNotice(item)"
                                    >
                                    </v-checkbox>
                                  </v-col>
                                </v-row>
                                <v-card-text class="headline-1 ma-4 pa-4">
                                  {{ item.detail }}
                                </v-card-text>
                              </v-row>
                              <v-row class="text-body-1 pa-4">
                                <v-col cols="10">
                                  <v-checkbox
                                    v-model="acceptTerms"
                                    label="I understand and accept the following terms and agree to sign the above documents"
                                  />
                                  <ul class="headline-1 ma-4 pa-4">
                                    <li>I have read the forms above and retained them for future reference</li>
                                    <li>I understand and agree to the potential actions and authorizations that they provide to Symetra</li>
                                    <li>I will answer all questions in this application for SwiftTerm life insurance truthfully and complete them to the best of my knowledge and belief</li>
                                    <li>I agree to authorize Symetra Life insurance company to affix my signature to the forms above and understand it has the same effect as signing the forms by hand</li>
                                  </ul>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blanketbutton darken-1"
                                outlined
                                :disabled="!validdoccheck || !acceptTerms"
                                @click="dialogack = false"
                              >
                                Confirm
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <!-------------------------------PERSONAL INFORMATION---------------------------------------->
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <p>{{ getquestiontext('fullname') }}</p>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="First Name"
                              :rules="{ required: true, regex: /^(?!\s)(?!\-)[a-zA-Z\- ]+[a-zA-Z]$/, max: 20 }"
                            >
                              <v-text-field
                                v-model="currentuser.firstname"
                                :error-messages="errors"
                                label="First Name"
                                counter="20"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Middle Initial"
                              rules="alpha"
                            >
                              <v-text-field
                                v-model="currentuser.middleinitial"
                                v-mask="'A'"
                                :error-messages="errors"
                                label="M.I."
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Last Name"
                              rules="required|alpha_dash|max:30"
                            >
                              <v-text-field
                                v-model="currentuser.lastname"
                                :error-messages="errors"
                                label="Last Name"
                                counter="30"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="2"
                            md="2"
                          >
                            <v-select
                              v-model="currentuser.suffix"
                              :items="suffixes"
                              label="Suffix"
                              color="primary"
                              outlined
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="8"
                          >
                            <p>Date of Birth</p>
                            <p>Nearest Age: {{ nearestAge }}</p>
                            <v-row>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="currentuser.bday"
                                  label="Day"
                                  outlined
                                  color="primary"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="currentuser.bmonth"
                                  item-text="state"
                                  item-value="abbr"
                                  label="Month"
                                  outlined
                                  color="primary"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-text-field
                                  v-model="currentuser.byear"
                                  label="Year"
                                  outlined
                                  color="primary"
                                  readonly
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <p>{{ getquestiontext('gender') }}</p>
                        <v-btn-toggle
                          v-model="currentuser.gender"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="Male"
                          >
                            Male
                          </v-btn>

                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="Female"
                          >
                            Female
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('maritalstatus') }}</p>
                        <validation-provider
                          name="maritalstatus"
                          rules="required"
                        >
                          <v-select
                            v-model="hinterview.maritalstatus"
                            :rules="present"
                            :items="maritallist"
                            label="Select"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>Social Security Number</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="ssn"
                          :rules="{ required: true, regex: /^(\d+-?)+\d+$/, min: 11, max: 11 }"
                        >
                          <v-text-field
                            v-model="currentuser.ssn"
                            v-mask="'###-##-####'"
                            :error-messages="errors"
                            label="Social Security Number"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>Email</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <v-text-field
                            v-model="currentuser.email"
                            :error-messages="errors"
                            label="Email"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('cellnumber') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Cell Phone Number"
                          :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                        >
                          <v-text-field
                            v-model="currentuser.mobile"
                            v-mask="'(###)-###-####'"
                            :error-messages="errors"
                            label="Cell Phone Number"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>Alternate Phone Number</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="altphone"
                          :rules="{regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                        >
                          <v-text-field
                            v-model="currentuser.alternatephone"
                            v-mask="'(###)-###-####'"
                            :error-messages="errors"
                            label="Alternate Phone Number"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('resaddress') }}</p>
                        <vuetify-google-autocomplete
                          id="map1"
                          ref="resaddress"
                          classname="form-control"
                          outlined
                          placeholder="Please type your address"
                          country="us"
                          @placechanged="getResidentialAddressData"
                        >
                        </vuetify-google-autocomplete>
                        <validation-provider
                          name="resaddress"
                          rules="required"
                        >
                          <v-text-field
                            v-model="currentuser.address"
                            label="Address"
                            color="primary"
                            outlined
                            readonly
                          />
                        </validation-provider>
                        <validation-provider
                          name="city"
                          rules="required"
                        >
                          <v-text-field
                            v-model="currentuser.city"
                            label="City"
                            color="primary"
                            outlined
                            readonly
                          />
                        </validation-provider>
                        <validation-provider
                          name="state"
                          rules="required"
                        >
                          <v-text-field
                            v-model="currentuser.state"
                            label="State"
                            color="primary"
                            outlined
                            readonly
                          />
                        </validation-provider>
                        <validation-provider
                          name="zipcode"
                          rules="required"
                        >
                          <v-text-field
                            v-model="currentuser.postcode"
                            label="Zipcode"
                            color="primary"
                            outlined
                            readonly
                          />
                        </validation-provider>
                        <p>Will the Applicant be the Owner for this Policy?</p>
                        <v-btn-toggle
                          v-model="insurredispolicyowner"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="kickoutToAgent()"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <!-- <div v-if="insurredispolicyowner === 'false'">
                          <p>
                            Owner {{ getquestiontext('fullname') }}
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="ownername"
                            rules="required"
                          >
                            <v-text-field
                              v-model="owner.firstName"
                              :error-messages="errors"
                              label="First Name"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Middle Initial"
                            rules="alpha|min:1|max:1"
                          >
                            <v-text-field

                              v-model="owner.middleInitial"
                              :error-messages="errors"
                              label="M.I."
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="ownerlastname"
                            rules="required"
                          >
                            <v-text-field
                              v-model="owner.lastName"
                              :error-messages="errors"
                              label="Last Name"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <p>
                            {{ getquestiontext('gender') }}
                          </p>
                          <v-btn-toggle
                            v-model="owner.gender"
                            mandatory
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="Male"
                            >
                              Male
                            </v-btn>

                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="Female"
                            >
                              Female
                            </v-btn>
                          </v-btn-toggle>
                          <p>Date of Birth</p>
                          <v-row>
                            <v-col
                              cols="12"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Birth Day"
                                rules="required"
                              >
                                <v-select
                                  v-model="owner.bday"
                                  :items="daysavailable"
                                  label="Day"
                                  outlined
                                  color="primary"
                                  :error-messages="errors"
                                ></v-select>
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Birth Month"
                                rules="required"
                              >
                                <v-select
                                  v-model="owner.bmonth"
                                  :items="monthsavailable"
                                  :error-messages="errors"
                                  item-text="state"
                                  item-value="abbr"
                                  label="Month"
                                  outlined
                                  color="primary"
                                ></v-select>
                              </validation-provider>
                            </v-col>
                            <v-col
                              cols="12"
                              md="4"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                name="Birth Year"
                                rules="required"
                              >
                                <v-select
                                  v-model="owner.byear"
                                  :items="yearsavailable2"
                                  :error-messages="errors"
                                  label="Year"
                                  outlined
                                  color="primary"
                                ></v-select>
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <p>
                            {{ getquestiontext('maritalstatus') }}
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="maritalstatus"
                            rules="required"
                          >
                            <v-select
                              v-model="hinterview.maritalstatus"
                              :rules="present"
                              :error-messages="errors"
                              :items="maritallist"
                              label="Select"
                              item-value="code"
                              item-text="description"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <p>
                            Owner Social Security Number
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="ownerssn"
                            :rules="{ required: true, regex: /^(\d+-?)+\d+$/, min: 11, max: 11 }"
                          >
                            <v-text-field
                              v-model="owner.ssn"
                              v-mask="'###-##-####'"
                              :error-messages="errors"
                              label="Social Security Number"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <p>
                            Owner Email
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="owneremail"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="owner.email"
                              :error-messages="errors"
                              label="Email"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <p>
                            Owner {{ getquestiontext('cellnumber') }}
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="ownercellnumber"
                            :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                          >
                            <v-text-field

                              v-model="owner.mobile"
                              v-mask="'(###)-###-####'"
                              :error-messages="errors"
                              label="Mobile Phone"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <p>
                            Owner {{ getquestiontext('resaddress') }}
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="owneraddress"
                            rules="required"
                          >
                            <v-text-field

                              v-model="owner.address"
                              :error-messages="errors"
                              label="Address"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="ownercity"
                            rules="required|alpha_spaces"
                          >
                            <v-text-field

                              v-model="owner.city"
                              :error-messages="errors"
                              label="City"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="State"
                            rules="required"
                          >
                            <v-autocomplete
                              v-model="owner.state"
                              :error-messages="errors"
                              :items="StateList"
                              label="State"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Zip"
                            :rules="{ required: true, regex: /^(\d{5}(\d{4})?)?$/ }"
                          >
                            <v-text-field

                              v-model="owner.postcode"
                              :error-messages="errors"
                              label="Zip"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                          <p>
                            {{ getquestiontext('relationshiptoinsured') }}
                          </p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="relationshiptoinsured"
                            rules="required"
                          >
                            <v-select

                              v-model="hinterview.relationshiptoinsured"
                              :rules="present"
                              :items="relationToInsuredList"
                              :error-messages="errors"
                              label="Source"
                              item-value="code"
                              item-text="description"
                              color="primary"
                              outlined
                            />
                          </validation-provider>
                        </div> -->
                        <!----------------------------------------PRIMARY BENEFICIARIES---------------------------------------->
                        <p class="text-center font-weight-bold">
                          Primary Beneficiaries
                        </p>
                        <p>How many Primary Beneficiaries are there?</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="primarybeneficiaries"
                          rules="required"
                        >
                          <v-select
                            v-model="numberOfPB"
                            outlined
                            color="primary"
                            :items="numberOfPBList"
                            :error-messages="errors"
                            @change="setAmountsPB()"
                          />
                        </validation-provider>
                        <v-col class="d-inline-flex align-center">
                          <v-checkbox
                            v-model="shareEquallyPB"
                            color="primary"
                            @click="splitEquallyPB()"
                          />
                          <p class="mt-3">
                            Share equally among all primary beneficiaries
                          </p>
                        </v-col>
                        <div
                          v-for="(beneficiary, index) in numberOfPB"
                          :key="index"
                        >
                          <p class="font-weight-bold">
                            Primary Beneficiary {{ beneficiary }}
                          </p>
                          <p>Relationship to Insured</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="relationshiptoinsured"
                            rules="required"
                          >
                            <v-select
                              v-model="primaryBeneficiaries[index].relationshipToInsured"
                              outlined
                              color="primary"
                              :items="beneficiaryRelationshipList"
                              :error-messages="errors"
                            />
                          </validation-provider>
                          <div v-if="primaryBeneficiaries[index].relationshipToInsured !== 'Trust' && primaryBeneficiaries[index].relationshipToInsured !== 'Estate' && primaryBeneficiaries[index].relationshipToInsured !== ''">
                            <p class="text-center font-weight-bold">
                              Individual
                            </p>
                            <p>What percentage should this Beneficiary receive?</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="percentage"
                              rules="required|numeric|min_value:1|max_value:100"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].percentageToRecieve"
                                v-mask="'###'"
                                :error-messages="errors"
                                :disabled="numberOfPB === 1 || shareEquallyPB"
                                label="Percentage"
                                color="primary"
                                outlined
                                suffix="%"
                                @change="calcPBPercentage()"
                              />
                            </validation-provider>
                            <v-row
                              v-if="percentageErrorPB === true"
                              class="d-flex justify-center"
                            >
                              <p class="red--text ma-2 pa-2">
                                The Total % Share of the Beneficiaries must equal 100%
                              </p>
                            </v-row>
                            <p>Full Legal Name</p>
                            <v-row>
                              <v-col
                                cols="12"
                                md="3"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="First Name"
                                  rules="required|alpha_spaces"
                                >
                                  <v-text-field

                                    v-model="primaryBeneficiaries[index].individual.firstName"
                                    :error-messages="errors"
                                    label="First Name"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="12"
                                md="3"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Middle Initial"
                                  rules="alpha|min:1|max:1"
                                >
                                  <v-text-field

                                    v-model="primaryBeneficiaries[index].individual.middleInitial"
                                    :error-messages="errors"
                                    label="M.I."
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="12"
                                md="3"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Last Name"
                                  :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                                >
                                  <v-text-field
                                    v-model="primaryBeneficiaries[index].individual.lastName"
                                    :error-messages="errors"
                                    label="Last Name"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="12"
                                md="3"
                              >
                                <v-select
                                  v-model="primaryBeneficiaries[index].individual.suffix"
                                  :items="suffixes"
                                  label="Suffix"
                                  color="primary"
                                  outlined
                                />
                              </v-col>
                            </v-row>
                            <p>Date of Birth</p>
                            <v-row>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Birth Day"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="primaryBeneficiaries[index].individual.bDay"
                                    :items="daysavailable"
                                    label="Day"
                                    outlined
                                    color="primary"
                                    :error-messages="errors"
                                    @change="formatDobPB(index)"
                                  ></v-select>
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Birth Month"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="primaryBeneficiaries[index].individual.bMonth"
                                    :items="monthsavailable"
                                    :error-messages="errors"
                                    label="Month"
                                    outlined
                                    color="primary"
                                    @change="formatDobPB(index)"
                                  ></v-select>
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Birth Year"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="primaryBeneficiaries[index].individual.bYear"
                                    :items="yearsavailable2"
                                    :error-messages="errors"
                                    label="Year"
                                    outlined
                                    color="primary"
                                    @change="formatDobPB(index)"
                                  ></v-select>
                                </validation-provider>
                              </v-col>
                            </v-row>
                            <p>Social Security Number</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="ssn"
                              :rules="{ required: true, regex: /^(\d+-?)+\d+$/, min: 11, max: 11 }"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].individual.ssn"
                                v-mask="'###-##-####'"
                                :error-messages="errors"
                                label="Social Security Number"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>Address</p>
                            <v-checkbox
                              v-model="primaryBeneficiaries[index].sameAddress"
                              label="Address same as insured"
                              @click="sameAddressPB(index)"
                            />
                            <validation-provider
                              v-slot="{ errors }"
                              name="Address"
                              rules="required"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].individual.residentialAddress.addressLine1"
                                :error-messages="errors"
                                label="Address"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <v-text-field
                              v-model="primaryBeneficiaries[index].individual.residentialAddress.addressLine2"
                              label="Address Line 2 (Optional)"
                              color="primary"
                              outlined
                            />
                            <validation-provider
                              v-slot="{ errors }"
                              name="City"
                              rules="required|alpha_spaces"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].individual.residentialAddress.city"
                                :error-messages="errors"
                                label="City"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="State"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="primaryBeneficiaries[index].individual.residentialAddress.state"
                                :error-messages="errors"
                                :items="StateList"
                                label="State"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Zip Code"
                              :rules="{ required: true, regex: /^(\d{5}(\d{4})?)?$/ }"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].individual.residentialAddress.zip"
                                :error-messages="errors"
                                label="Zip Code"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>Phone Number</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Phone Number"
                              :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].individual.phone"
                                v-mask="'(###)-###-####'"
                                :error-messages="errors"
                                label="Phone Number"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>
                              Email
                            </p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].email"
                                :error-messages="errors"
                                label="Email"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                          </div>
                          <!-----------------------------------PB = ORGANIZATION------------------------------------->
                          <div v-if="primaryBeneficiaries[index].relationshipToInsured === 'Trust' || primaryBeneficiaries[index].relationshipToInsured === 'Estate'">
                            <p class="text-center font-weight-bold">
                              Organization
                            </p>
                            <p>What percentage should this Beneficiary receive?</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="percentage"
                              rules="required|numeric|min_value:1|max_value:100"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].percentageToRecieve"
                                v-mask="'###'"
                                :error-messages="errors"
                                :disabled="numberOfPB === 1 || shareEquallyPB"
                                label="Percentage"
                                color="primary"
                                outlined
                                suffix="%"
                                @change="calcPBPercentage()"
                              />
                            </validation-provider>
                            <v-row
                              v-if="percentageErrorPB === true"
                              class="d-flex justify-center"
                            >
                              <p class="red--text ma-2 pa-2">
                                The Total % Share of the Primary Beneficiaries must equal 100%
                              </p>
                            </v-row>
                            <p>Organization Name</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Organization Name"
                              rules="required"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.name"
                                :error-messages="errors"
                                label="Organization Name"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>Date Established</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Date Established"
                              rules="required"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.established"
                                v-mask="'##/##/####'"
                                :error-messages="errors"
                                label="Date Established"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>TIN</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="TIN"
                              rules="required"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.tin"
                                v-mask="'#########'"
                                :error-messages="errors"
                                label="TIN"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>Address</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Address"
                              rules="required"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.address.addressLine1"
                                :error-messages="errors"
                                label="Address"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <v-text-field
                              v-model="primaryBeneficiaries[index].organization.address.addressLine2"
                              label="Address Line 2 (Optional)"
                              color="primary"
                              outlined
                            />
                            <validation-provider
                              v-slot="{ errors }"
                              name="City"
                              rules="required"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.address.city"
                                :error-messages="errors"
                                label="City"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="State"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="primaryBeneficiaries[index].organization.address.state"
                                :error-messages="errors"
                                :items="StateList"
                                label="State"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Zip Code"
                              :rules="{ required: true, regex: /^(\d{5}(\d{4})?)?$/ }"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.address.zip"
                                :error-messages="errors"
                                label="Zip Code"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>Phone Number</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Phone Number"
                              :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.phone"
                                v-mask="'(###)-###-####'"
                                :error-messages="errors"
                                label="Phone Number"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                            <p>
                              Email
                            </p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Email"
                              rules="required|email"
                            >
                              <v-text-field
                                v-model="primaryBeneficiaries[index].organization.email"
                                :error-messages="errors"
                                label="Email"
                                color="primary"
                                outlined
                              />
                            </validation-provider>
                          </div>
                        </div>
                        <p>Would you like to designate a contingent beneficiary?</p>
                        <v-btn-toggle
                          v-model="designateContingent"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="No"
                            @click="ctr_future = 'No'"
                          >
                            No
                          </v-btn>

                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="Yes"
                            @click="ctr_future = 'Yes'"
                          >
                            Yes
                          </v-btn>
                        </v-btn-toggle>
                        <!----------------------------------------CONTINGENT BENEFICIARIES---------------------------------------->
                        <div v-if="designateContingent === 'Yes'">
                          <p class="text-center font-weight-bold">
                            Contingent Beneficiaries
                          </p>
                          <p>How many Contingent Beneficiaries are there?</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="contingent beneficiaries"
                            rules="required"
                          >
                            <v-select
                              v-model="numberOfCB"
                              outlined
                              color="primary"
                              :items="numberOfPBList"
                              :error-messages="errors"
                              @change="setAmountsCB()"
                            />
                          </validation-provider>
                          <v-col class="d-inline-flex align-center">
                            <v-checkbox
                              v-model="shareEquallyCB"
                              color="primary"
                              @click="splitEquallyCB()"
                            />
                            <p class="mt-3">
                              Share equally among all contingent beneficiaries?
                            </p>
                          </v-col>
                          <div
                            v-for="(b, i) in numberOfCB"
                            :key="i"
                          >
                            <p class="font-weight-bold">
                              Contingent Beneficiary {{ b }}
                            </p>
                            <p>Relationship to Insured</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="relationshiptoinsured"
                              rules="required"
                            >
                              <v-select
                                v-model="contingentBeneficiaries[i].relationshipToInsured"
                                outlined
                                color="primary"
                                :items="beneficiaryRelationshipList"
                                :error-messages="errors"
                              />
                            </validation-provider>
                            <div v-if="contingentBeneficiaries[i].relationshipToInsured !== 'Trust' && contingentBeneficiaries[i].relationshipToInsured !== 'Estate' && contingentBeneficiaries[i].relationshipToInsured !== ''">
                              <p class="text-center font-weight-bold">
                                Individual
                              </p>
                              <p>What percentage should this Beneficiary receive?</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="percentage"
                                rules="required|numeric|min_value:1|max_value:100"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].percentageToRecieve"
                                  v-mask="'###'"
                                  :error-messages="errors"
                                  :disabled="numberOfCB === 1 || shareEquallyCB"
                                  label="Percentage"
                                  color="primary"
                                  outlined
                                  suffix="%"
                                  @change="calcCBPercentage()"
                                />
                              </validation-provider>
                              <v-row
                                v-if="percentageErrorCB === true"
                                class="d-flex justify-center"
                              >
                                <p class="red--text ma-2 pa-2">
                                  The Total % Share of the Beneficiaries must equal 100%
                                </p>
                              </v-row>
                              <p>Full Legal Name</p>
                              <v-row>
                                <v-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="First Name"
                                    rules="required|alpha_spaces"
                                  >
                                    <v-text-field

                                      v-model="contingentBeneficiaries[i].individual.firstName"
                                      :error-messages="errors"
                                      label="First Name"
                                      color="primary"
                                      outlined
                                    />
                                  </validation-provider>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="Middle Initial"
                                    rules="alpha|min:1|max:1"
                                  >
                                    <v-text-field

                                      v-model="contingentBeneficiaries[i].individual.middleInitial"
                                      :error-messages="errors"
                                      label="M.I."
                                      color="primary"
                                      outlined
                                    />
                                  </validation-provider>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="Last Name"
                                    :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                                  >
                                    <v-text-field
                                      v-model="contingentBeneficiaries[i].individual.lastName"
                                      :error-messages="errors"
                                      label="Last Name"
                                      color="primary"
                                      outlined
                                    />
                                  </validation-provider>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="3"
                                >
                                  <v-select
                                    v-model="contingentBeneficiaries[i].individual.suffix"
                                    :items="suffixes"
                                    label="Suffix"
                                    color="primary"
                                    outlined
                                  />
                                </v-col>
                              </v-row>
                              <p>Date of Birth</p>
                              <v-row>
                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="Birth Day"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="contingentBeneficiaries[i].individual.bDay"
                                      :items="daysavailable"
                                      label="Day"
                                      outlined
                                      color="primary"
                                      :error-messages="errors"
                                      @change="formatDobCB(i)"
                                    ></v-select>
                                  </validation-provider>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="Birth Month"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="contingentBeneficiaries[i].individual.bMonth"
                                      :items="monthsavailable"
                                      :error-messages="errors"
                                      label="Month"
                                      outlined
                                      color="primary"
                                      @change="formatDobCB(i)"
                                    ></v-select>
                                  </validation-provider>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="Birth Year"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="contingentBeneficiaries[i].individual.bYear"
                                      :items="yearsavailable2"
                                      :error-messages="errors"
                                      label="Year"
                                      outlined
                                      color="primary"
                                      @change="formatDobCB(i)"
                                    ></v-select>
                                  </validation-provider>
                                </v-col>
                              </v-row>
                              <p>Social Security Number</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="ssn"
                                :rules="{ required: true, regex: /^(\d+-?)+\d+$/, min: 11, max: 11 }"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].individual.ssn"
                                  v-mask="'###-##-####'"
                                  :error-messages="errors"
                                  label="Social Security Number"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>Address</p>
                              <v-checkbox
                                v-model="contingentBeneficiaries[i].sameAddress"
                                label="Address same as insured"
                                @click="sameAddressCB(i)"
                              />
                              <validation-provider
                                v-slot="{ errors }"
                                name="Address"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].individual.residentialAddress.addressLine1"
                                  :error-messages="errors"
                                  label="Address"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <v-text-field
                                v-model="contingentBeneficiaries[i].individual.residentialAddress.addressLine2"
                                label="Address Line 2 (Optional)"
                                color="primary"
                                outlined
                              />
                              <validation-provider
                                v-slot="{ errors }"
                                name="City"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].individual.residentialAddress.city"
                                  :error-messages="errors"
                                  label="City"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <validation-provider
                                v-slot="{ errors }"
                                name="State"
                                rules="required"
                              >
                                <v-autocomplete
                                  v-model="contingentBeneficiaries[i].individual.residentialAddress.state"
                                  :error-messages="errors"
                                  :items="StateList"
                                  label="State"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Zip Code"
                                :rules="{ required: true, regex: /^(\d{5}(\d{4})?)?$/ }"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].individual.residentialAddress.zip"
                                  :error-messages="errors"
                                  label="Zip Code"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>Phone Number</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Phone Number"
                                :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].individual.phone"
                                  v-mask="'(###)-###-####'"
                                  :error-messages="errors"
                                  label="Phone Number"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>
                                Email
                              </p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required|email"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].individual.email"
                                  :error-messages="errors"
                                  label="Email"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                            </div>
                            <div v-if="contingentBeneficiaries[i].relationshipToInsured === 'Trust' || contingentBeneficiaries[i].relationshipToInsured === 'Estate'">
                              <p class="text-center font-weight-bold">
                                Organization
                              </p>
                              <p>What percentage should this Beneficiary receive?</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="percentage"
                                rules="required|numeric|min_value:1|max_value:100"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].percentageToRecieve"
                                  v-mask="'###'"
                                  :error-messages="errors"
                                  :disabled="numberOfCB === 1 || shareEquallyCB"
                                  label="Percentage"
                                  color="primary"
                                  outlined
                                  suffix="%"
                                  @change="calcCBPercentage()"
                                />
                              </validation-provider>
                              <v-row
                                v-if="percentageErrorCB === true"
                                class="d-flex justify-center"
                              >
                                <p class="red--text ma-2 pa-2">
                                  The Total % Share of the Beneficiaries must equal 100%
                                </p>
                              </v-row>
                              <p>Organization Name</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Organization Name"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.name"
                                  :error-messages="errors"
                                  label="Organization Name"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>Date Established</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Date Established"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.established"
                                  v-mask="'##/##/####'"
                                  :error-messages="errors"
                                  label="Date Established"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>Address</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Address"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.address.addressLine1"
                                  :error-messages="errors"
                                  label="Address"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <v-text-field
                                v-model="contingentBeneficiaries[i].organization.address.addressLine2"
                                label="Address Line 2 (Optional)"
                                color="primary"
                                outlined
                              />
                              <validation-provider
                                v-slot="{ errors }"
                                name="City"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.address.city"
                                  :error-messages="errors"
                                  label="City"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <validation-provider
                                v-slot="{ errors }"
                                name="State"
                                rules="required"
                              >
                                <v-autocomplete
                                  v-model="contingentBeneficiaries[i].organization.address.state"
                                  :error-messages="errors"
                                  :items="StateList"
                                  label="State"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Zip Code"
                                :rules="{ required: true, regex: /^(\d{5}(\d{4})?)?$/ }"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.address.zip"
                                  :error-messages="errors"
                                  label="Zip Code"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>Phone Number</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Phone Number"
                                :rules="{ required: true, regex: /^[- +()]*[0-9][- +()0-9]*$/, min: 14, max: 14 }"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.phone"
                                  v-mask="'(###)-###-####'"
                                  :error-messages="errors"
                                  label="Phone Number"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>
                                Email
                              </p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required|email"
                              >
                                <v-text-field
                                  v-model="contingentBeneficiaries[i].organization.email"
                                  :error-messages="errors"
                                  label="Email"
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="ma-2 pa-2">
                      <p>Symetra may use the information you provided to request our third-party verification service confirm your identity and prevent misuse of your data.  By clicking    Continue you agree with Symetra performing this identity authentication</p>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      to="/"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      :disabled="invalid || percentageErrorCB || percentageErrorPB"
                      @click="nextquestion('term life options'); logData()"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </validation-observer>
              </v-stepper-content>
              <!------------------------------------------------------STEP 2 COVERAGE INFORMATION-------------------------------------------------->
              <validation-observer
                v-slot="{ invalid }"
                ref="observer"
              >
                <v-stepper-content step="2">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Coverage
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                        >
                          <v-divider
                            class="test text-center"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <div
                          v-if="currentuser.state !== 'CA'"
                          class="mt-2"
                        >
                          <h3 class="font-weight-bold primary--text">
                            You are applying for a term {{ getpolicydetails.termlength }} policy with a face amount of {{ format_amt(getpolicydetails.policyvalue) }}
                          </h3>
                          <p class="mt-4 font-weight-bold primary--text">
                            Optional Riders and Benefits
                          </p>
                          <v-col
                            class="pa-0 ma-0"
                            cols="12"
                          >
                            <v-checkbox
                              v-model="acdb"
                            >
                              <template v-slot:label>
                                <div class="headline-1 ma-0 pa-0 blanketcolor--text">
                                  Accelerated death benefit for terminal illness
                                </div>
                              </template>
                            </v-checkbox>
                            <p class="text-body-1 mb-4 pa-2">
                              Up to 75% of the policy’s death benefit ($500,000 maximum) can be accessed in advance if the insured is diagnosed as terminally ill with less than 12 months to live.
                            </p>
                          </v-col>
                          <v-col
                            class="pa-0 ma-0"
                            cols="12"
                          >
                            <v-checkbox
                              v-model="adb"
                            >
                              <template v-slot:label>
                                <div class="headline-1 ma-0 pa-0 blanketcolor--text">
                                  Accidental death benefit rider <div v-if="ADBPrices">
                                    Monthly Price: {{ ADBPrices.monthlyprice }} / Annual Price: {{ ADBPrices.annualprice }}
                                  </div>
                                </div>
                              </template>
                            </v-checkbox>
                            <p class="text-body-1 mb-4 pa-2">
                              If the insured’s death is accidental, this rider provides an additional death benefit of three times the policy’s face amount ($250,000 maximum).
                            </p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Amount"
                              rules="numeric|min_value:5000|max_value:250000"
                            >
                              <v-text-field
                                v-if="adb"
                                v-model="adbAmount"
                                prefix="$"
                                :error-messages="errors"
                                label="Amount"
                                color="primary"
                                outlined
                                @blur="calculateADBprices()"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            class="pa-0 ma-0"
                            cols="12"
                          >
                            <v-checkbox
                              v-model="ctr"
                            >
                              <template v-slot:label>
                                <div class="headline-1 ma-0 pa-0 blanketcolor--text">
                                  Children’s term life insurance rider
                                </div>
                              </template>
                            </v-checkbox>
                            <p class="text-body-1 mb-4 pa-2">
                              This rider provides $1,000 to $10,000 of life insurance coverage on dependent children ages 15 days through 17 years.
                            </p>
                            <div
                              v-if="ctr"
                            >
                              <p>In the event you do not currently have any children, did you wish to elect this coverage for future children born to or adopted by you?</p>
                              <v-btn-toggle
                                v-model="ctr_future"
                                mandatory
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="No"
                                  @click="ctr_future = 'No'"
                                >
                                  No
                                </v-btn>

                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="Yes"
                                  @click="ctr_future = 'Yes'"
                                >
                                  Yes
                                </v-btn>
                              </v-btn-toggle>
                            </div>
                            <div v-if="ctr_future === 'Yes'">
                              <p>Child's Name</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Amount"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="ctrFuture_name"
                                  :error-messages="errors"
                                  filled
                                  disabled
                                  color="primary"
                                  outlined
                                />
                              </validation-provider>
                              <p>Child's coverage amount</p>
                              <validation-provider
                                v-slot="{ errors }"
                                name="Amount"
                                rules="required"
                              >
                                <v-select
                                  v-model="ctrFuture_amount"
                                  :items="ctrAmounts"
                                  :error-messages="errors"
                                  label="Amount"
                                  prefix="$"
                                  outlined
                                  @change="getCrFutureRate()"
                                />
                              </validation-provider>
                              <p v-if="ctrFuture_amount !== ''">
                                Annual Price: {{ ICBPrices[0].annualprice }}
                              </p>
                              <p v-if="ctrFuture_amount !== ''">
                                Monthly Price: {{ ICBPrices[0].monthprice }}
                              </p>
                            </div>
                            <div v-if="ctr && ctr_future === 'No'">
                              <p>How many children would you like to cover? (max 5)</p>
                              <v-select
                                v-model="ctr_number"
                                :items="ctr_numbers"
                                label="Number of Children"
                                outlined
                              />
                              <div
                                v-for="(child, index) in ctr_number"
                                :key="index"
                                class="bordered pa-4 mb-2"
                              >
                                <p class="font-weight-bold">
                                  Child {{ child }}
                                </p>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Amount"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="ctr_children[index].amount"
                                    :items="ctrAmounts"
                                    :error-messages="errors"
                                    label="Amount"
                                    prefix="$"
                                    outlined
                                    @change="getCrRates()"
                                  />
                                </validation-provider>
                                <p v-if="ctr_children[index].amount">
                                  Annual Price: {{ ICBPrices[index].annualprice }}
                                </p>
                                <p v-if="ctr_children[index].amount">
                                  Monthly Price: {{ ICBPrices[index].monthprice }}
                                </p>
                                <p>Full Legal Name</p>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="3"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="First Name"
                                      rules="required|alpha_spaces"
                                    >
                                      <v-text-field

                                        v-model="ctr_children[index].firstName"
                                        :error-messages="errors"
                                        label="First Name"
                                        color="primary"
                                        outlined
                                      />
                                    </validation-provider>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="3"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Middle Initial"
                                      rules="alpha|min:1|max:1"
                                    >
                                      <v-text-field

                                        v-model="ctr_children[index].middleInitial"
                                        :error-messages="errors"
                                        label="M.I."
                                        color="primary"
                                        outlined
                                      />
                                    </validation-provider>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="3"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Last Name"
                                      :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                                    >
                                      <v-text-field
                                        v-model="ctr_children[index].lastName"
                                        :error-messages="errors"
                                        label="Last Name"
                                        color="primary"
                                        outlined
                                      />
                                    </validation-provider>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="3"
                                  >
                                    <v-select
                                      v-model="ctr_children[index].suffix"
                                      :items="suffixes"
                                      label="Suffix"
                                      color="primary"
                                      outlined
                                    />
                                  </v-col>
                                </v-row>
                                <p>Date of Birth</p>
                                <p
                                  v-if="childAgeError"
                                  class="red--text"
                                >
                                  Only children ages 15 days through 17 years are eligible for the Insured Children's Benefit Rider.
                                </p>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Birth Day"
                                      rules="required"
                                    >
                                      <v-select
                                        v-model="ctr_children[index].bDay"
                                        :items="daysavailable"
                                        label="Day"
                                        outlined
                                        color="primary"
                                        :error-messages="errors"
                                        @change="calcChildAge(ctr_children[index])"
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Birth Month"
                                      rules="required"
                                    >
                                      <v-select
                                        v-model="ctr_children[index].bMonth"
                                        :items="monthsavailable"
                                        :error-messages="errors"
                                        label="Month"
                                        outlined
                                        color="primary"
                                        @change="calcChildAge(ctr_children[index])"
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Birth Year"
                                      rules="required"
                                    >
                                      <v-select
                                        v-model="ctr_children[index].bYear"
                                        :items="yearsavailable"
                                        :error-messages="errors"
                                        label="Year"
                                        outlined
                                        color="primary"
                                        @change="calcChildAge(ctr_children[index])"
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                                <p>Social Security Number  (if available)</p>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="ssn"
                                  :rules="{ required: true, regex: /^(\d+-?)+\d+$/, min: 11, max: 11 }"
                                >
                                  <v-text-field
                                    v-model="ctr_children[index].ssn"
                                    v-mask="'###-##-####'"
                                    :error-messages="errors"
                                    label="Social Security Number"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                                <p>Gender</p>
                                <v-btn-toggle
                                  v-model="ctr_children[index].gender"
                                  mandatory
                                  class="mb-4"
                                >
                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="Male"
                                  >
                                    Male
                                  </v-btn>

                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="Female"
                                  >
                                    Female
                                  </v-btn>

                                  <v-btn
                                    color="blanketbutton"
                                    text
                                    outlined
                                    value="Female"
                                  >
                                    Non-Binary
                                  </v-btn>
                                </v-btn-toggle>
                                <p>Address</p>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Address"
                                  rules="required"
                                >
                                  <v-text-field
                                    v-model="ctr_children[index].streetLine1"
                                    :error-messages="errors"
                                    label="Address"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="City"
                                  rules="required"
                                >
                                  <v-text-field
                                    v-model="ctr_children[index].city"
                                    :error-messages="errors"
                                    label="City"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="State"
                                  rules="required"
                                >
                                  <v-autocomplete
                                    v-model="ctr_children[index].state"
                                    :error-messages="errors"
                                    :items="StateList"
                                    label="State"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Zip Code"
                                  :rules="{ required: true, regex: /^(\d{5}(\d{4})?)?$/ }"
                                >
                                  <v-text-field
                                    v-model="ctr_children[index].zip"
                                    :error-messages="errors"
                                    label="Zip Code"
                                    color="primary"
                                    outlined
                                  />
                                </validation-provider>
                              </div>
                            </div>
                          </v-col>
                          <v-col
                            class="pa-0 ma-0"
                            cols="12"
                          >
                            <v-checkbox
                              v-model="wop"
                            >
                              <template v-slot:label>
                                <div class="headline-1 ma-0 pa-0 blanketcolor--text">
                                  Waiver of premium rider  Monthly Price: {{ WOPPrices.monthlyprice }} / Annual Price: {{ WOPPrices.annualprice }}
                                </div>
                              </template>
                            </v-checkbox>
                            <p class="text-body-1 mb-4 pa-2">
                              Premium payments will be waived if the insured becomes critically ill, seriously injured, or disabled for a consecutive period of at least 6 months.
                            </p>
                          </v-col>
                          <!-- Accelerated death benefit for terminal illness
Up to 75% of the policy’s death benefit ($500,000 maximum) can be accessed in advance if the insured is diagnosed as terminally ill with less than 12 months to live.

Accidental death benefit rider

If the insured’s death is accidental, this rider provides an additional death benefit of three times the policy’s face amount ($250,000 maximum).

Children’s term life insurance rider

This rider provides $1,000 to $10,000 of life insurance coverage on dependent children ages 15 days through 17 years.

Waiver of premium rider

Premium payments will be waived if the insured becomes critically ill, seriously injured, or disabled for a consecutive period of at least 6 months.
 -->
                        </div>
                        <p>Do you currently have life insurance or an annuity, not including insurance through an employer?</p>
                        <v-btn-toggle
                          v-model="currentLifeInsurance"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>Will the coverage applied for replace or change any existing life insurance or annuity contract with the company or any company?</p>
                        <validation-provider
                          name="replaceexistinglifeinsurance"
                          rules="required"
                        >
                          <v-btn-toggle
                            v-model="existingCompanyInsurance"
                            class="mb-4"
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </validation-provider>
                      <!---------------------------------------
                      <div v-if="existingCompanyInsurance === 'true' && currentuser.state === 'CA'">
                        <v-row>
                          <v-col class="text-center font-weight-bold">
                            <p>{{ CAReplacementData.labels[0] }}</p>
                          </v-col>
                        </v-row>
                        <p>{{ CAReplacementData.labels[1] }}</p>
                        <v-checkbox
                          v-model="ca1"
                          :label="CAReplacementData.survey[0].text"
                        ></v-checkbox>
                        <v-text-field
                          v-if="ca1"
                          v-model="ca6"
                          outlined
                          color="primary"
                          :label="CAReplacementData.survey[1].text"
                          maxlength="50"
                          counter
                        ></v-text-field>
                        <v-checkbox
                          v-model="ca3"
                          :label="CAReplacementData.survey[2].text"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="ca4"
                          :label="CAReplacementData.survey[3].text"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="ca5"
                          :label="CAReplacementData.survey[4].text"
                        ></v-checkbox>
                        <v-text-field
                          v-if="ca5"
                          v-model="ca6"
                          outlined
                          color="primary"
                          :label="CAReplacementData.survey[5].text"
                          maxlength="50"
                          counter
                        ></v-text-field>
                      </div> -------->
                      <!-----------------------------------------Existing Insurance---------------------------------------------->
                      <!-- <div v-if="hasExistingInsurance && specialStates.includes(currentuser.state)">
                        <h3 class="font-weight-bold primary--text">
                          Existing Insurance Details
                        </h3>
                        <v-row class="mt-2">
                          <v-col
                            cols="12"
                          >
                            <div v-if="currentuser.state === 'GA'">
                              <v-checkbox
                                v-model="georgiaSummaryStatement"
                                label="If you wish a policy summary statement from your existing insurer, or insurers, check this box."
                              ></v-checkbox>
                            </div>
                            <div v-if="currentuser.state === 'KY'">
                              <p>Are you considering discontinuing making premium payments, surrendering, forfeiting, assigning to the insurer, or otherwise terminating your existing policy or contract?</p>
                              <v-btn-toggle
                                v-model="consideringDiscontinuing"
                                mandatory
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                              <p>Are you considering using funds from your existing policies or contracts to pay premiums due on the new policy or contract?</p>
                              <v-btn-toggle
                                v-model="useExistingFunds"
                                mandatory
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                              <p>Make sure you know the facts. Contact your existing company or its agent for information about the old policy or contract. If you request one, an in-force illustration, policy summary or available disclosure documents must be sent to you by the existing insurer. Ask for and retain all sales material used by the agent in the sales presentation. Be sure that you are making an informed decision. The existing policy or contract is being replaced because</p>
                              <v-text-field
                                v-model="reasonForReplacement"
                                outlined
                                color="primary"
                                label="Reason for Replacement"
                              ></v-text-field>
                              <p>Did the agent/registered representative present and leave the applicant insurer-approved sales material?</p>
                              <v-btn-toggle
                                v-model="leaveSalesMaterial"
                                mandatory
                                class="mb-4"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                              <v-text-field
                                v-if="leaveSalesMaterial === 'true'"
                                v-model="approvedSalesMaterials"
                                outlined
                                color="primary"
                                label="Symetra approved sales materials:"
                              />
                            </div>
                            <div v-if="currentuser.state === 'MA'">
                              <v-checkbox
                                v-model="requestYieldIndices"
                                label="Check this box to request yield indices for cash value policies."
                              ></v-checkbox>
                            </div>
                            <div v-if="currentuser.state === 'MI'">
                              <p>I certify that this form and the Notice to Applicants Regarding Replacement of Life Insurance were given to and signed by</p>
                              <v-text-field
                                v-model="applicantCertifyMI"
                                label="Applicant"
                                outlined
                                color="primary"
                              ></v-text-field>
                            </div>
                            <div v-if="currentuser.state === 'MN'">
                              <p>I certify that this form was given to and completed by the applicant prior to taking an application and that I am leaving a signed copy for the applicant.</p>
                              <v-text-field
                                v-model="applicantCertifyMN"
                                label="Applicant"
                                outlined
                                color="primary"
                              ></v-text-field>
                            </div>
                            <div v-if="currentuser.state === 'OK'">
                              <p>STATEMENT BY APPLICANT REGARDING NOTIFICATION OF REPLACEMENT TO THE REPLACED INSURER</p>
                              <p>Please notify my present insurer(s) regarding this transaction.</p>
                              <v-btn-toggle
                                v-model="notifyInsurer"
                                mandatory
                                class="mb-8"
                              >
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="true"
                                >
                                  Yes
                                </v-btn>
                                <v-btn
                                  color="blanketbutton"
                                  text
                                  outlined
                                  value="false"
                                >
                                  No
                                </v-btn>
                              </v-btn-toggle>
                            </div>
                            <v-select
                              v-model="numExisitingPolicies"
                              label="How many existing policies do you have?"
                              :items="policyNums"
                              color="primary"
                              outlined
                            />
                            <v-card
                              v-for="(n, index) in numExisitingPolicies"
                              :key="index"
                              class="pa-4"
                            >
                              <div>
                                <div class="text-center font-wieght-black text-h3 primary--text">
                                  Policy {{ n }}
                                </div>
                                <v-col>
                                  <v-select
                                    v-for="(dropdown, i) in existingInsuranceDropdowns"
                                    :key="i"
                                    v-model="existingPolicies[index].companyName"
                                    outlined
                                    color="primary"
                                    :label="dropdown.text"
                                    :items="companies"
                                  />
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-for="(textbox, i) in existingInsuranceTextboxes"
                                    :key="i"
                                    v-model="existingPolicies[index].textboxes[i]"
                                    :label="textbox.text"
                                    color="primary"
                                    outlined
                                  />
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-for="(textbox, i) in existingInsuranceAddresses"
                                    :key="i"
                                    v-model="existingPolicies[index].addresses[i]"
                                    :label="textbox.text"
                                    color="primary"
                                    outlined
                                  />
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-for="(textbox, i) in existingInsuranceDates"
                                    :key="i"
                                    v-model="existingPolicies[index].dates[i]"
                                    v-mask="'##/##/####'"
                                    :label="textbox.text"
                                    color="primary"
                                    outlined
                                  />
                                </v-col>
                                <v-row
                                  v-for="(radio, i) in existingInsuranceRadioButtons"
                                  :key="i"
                                >
                                  <v-col class="ml-2 mt-0 pt-0 mb-2 d-inline-flex align-center">
                                    <p class="mr-6">
                                      Policy {{ n }} {{ radio.text }}
                                    </p>
                                    <v-btn-toggle
                                      v-model="existingPolicies[index].summaryRequest"
                                      mandatory
                                      class="mb-4"
                                    >
                                      <v-btn
                                        color="blanketbutton"
                                        text
                                        outlined
                                        value="true"
                                      >
                                        Yes
                                      </v-btn>
                                      <v-btn
                                        color="blanketbutton"
                                        text
                                        outlined
                                        value="false"
                                      >
                                        No
                                      </v-btn>
                                    </v-btn-toggle>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div> -->
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      to="/"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="back"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('term life options')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <!--------------------------------------STEP 3: LIFESTYLE--------------------------------------->
              <validation-observer
                v-slot="{ invalid }"
                ref="observer"
              >
                <v-stepper-content step="3">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Lifestyle
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                        >
                          <v-divider
                            class="test text-center"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <!-- <v-div
                        v-for="(item, i) in appQuestions"
                        :key="i"
                      >
                        <p>{{ getquestiontext(item.label) }}</p>
                        <v-text-field
                          v-model="item.useranswer"
                          color="primary"
                          outlined
                        />
                                              <v-text-field
                        :value="getQuestionId('work')"
                        color="primary"
                        outlined
                      />
                      </v-div> -->
                        <p>{{ getquestiontext('work') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Employment"
                        >
                          <v-select
                            v-model="hinterview.work"
                            :error-messages="errors"
                            :items="employment_options"
                            label="Select"
                            item-value="answerValue"
                            item-text="answerText"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('work type') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Employment Type"
                        >
                          <v-autocomplete
                            v-model="hinterview.work_type"
                            color="primary"
                            :error-messages="errors"
                            outlined
                            :items="occupations"
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('hours') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Hours"
                        >
                          <v-select
                            v-model="hinterview.hours"
                            :error-messages="errors"
                            :items="hoursWorked"
                            label="Hours Worked"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('military') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.military"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('disabled') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.disabled"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('household income') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Household Income"
                        >
                          <v-select
                            v-model="hinterview.household_income"
                            :error-messages="errors"
                            :items="Income"
                            label="Income"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('net worth') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Net Worth"
                        >
                          <v-select
                            v-model="hinterview.net_worth"
                            :error-messages="errors"
                            :items="netWorth"
                            label="Net Worth"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('sourceoffunds') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Source of Funds"
                        >
                          <v-select
                            v-model="hinterview.sourceoffunds"
                            :error-messages="errors"
                            :items="fundsList"
                            label="Source"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('bankruptcy') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.bankruptcy"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('need') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Need"
                          rules="required"
                        >
                          <v-select
                            v-model="hinterview.need"
                            :error-messages="errors"
                            :items="insuranceNeed"
                            label="Need"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('drive_yn') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.applicantdrives"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p v-if="hinterview.applicantdrives ==='true'">
                          Issuing State
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|aplha"
                          name="Issuing State"
                        >
                          <v-text-field
                            v-if="hinterview.applicantdrives ==='true'"
                            v-model="hinterview.dl_state"
                            color="primary"
                            :error-messages="errors"
                            outlined
                          />
                        </validation-provider>
                        <p v-if="hinterview.applicantdrives ==='true'">
                          License Number
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|numeric"
                          name="License Number"
                        >
                          <v-text-field
                            v-if="hinterview.applicantdrives ==='true'"
                            v-model="hinterview.dl_number"
                            :error-messages="errors"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                          />
                        </validation-provider>
                        <p v-if="hinterview.applicantdrives ==='false'">
                          {{ getquestiontext('driving') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Driving"
                        >
                          <v-select
                            v-if="hinterview.applicantdrives ==='false'"
                            v-model="hinterview.driving"
                            :error-messages="errors"
                            :items="drivingStatus"
                            label="Select"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>
                          {{ getquestiontext('moving violations') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Moving Violations"
                        >
                          <v-select
                            v-model="hinterview.moving_violations"
                            :error-messages="errors"
                            :items="movingViolations"
                            label="Select"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('applied before') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.applied_before"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('declined') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.declined"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('work out') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.work_out"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('hobby') }}</p>
                        <v-combobox
                          v-model="hobbies"
                          :items="activitylist"
                          label="Activities"
                          multiple
                          outlined
                          @change="hobbyCheck()"
                        ></v-combobox>
                        <div
                          v-if="basejumper"
                          class="mb-2"
                        >
                          <p>{{ followUps[0].aliasName }}: </p>
                          <p>{{ followUps[0].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="baseJumpingOptions"
                            mandatory
                          >
                            <v-btn
                              v-for="(answer, i) in followUps[0].questions[0].options"
                              :key="i"
                              color="blanketbutton"
                              text
                              outlined
                              :value="answer"
                            >
                              {{ answer }}
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="fighter"
                          class="mb-2"
                        >
                          <p>{{ followUps[1].aliasName }}: </p>
                          <p>{{ followUps[1].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="fightingOptions"
                            mandatory
                          >
                            <v-btn
                              v-for="(answer, i) in followUps[1].questions[0].options"
                              :key="i"
                              color="blanketbutton"
                              text
                              outlined
                              :value="answer"
                            >
                              {{ answer }}
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="bungee"
                          class="mb-2"
                        >
                          <p>{{ followUps[2].aliasName }}: </p>
                          <p>{{ followUps[2].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="bungeeOptions"
                            mandatory
                          >
                            <v-btn
                              v-for="(answer, i) in followUps[2].questions[0].options"
                              :key="i"
                              color="blanketbutton"
                              text
                              outlined
                              :value="answer"
                            >
                              {{ answer }}
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="caver"
                          class="mb-2"
                        >
                          <p>{{ followUps[3].aliasName }}: </p>
                          <p>{{ followUps[3].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="caverOptions"
                            mandatory
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="climber"
                          class="mb-2"
                        >
                          <p>{{ followUps[4].aliasName }}: </p>
                          <p>{{ followUps[4].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="climberOptions"
                            mandatory
                          >
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="true"
                            >
                              Yes
                            </v-btn>
                            <v-btn
                              color="blanketbutton"
                              text
                              outlined
                              value="false"
                            >
                              No
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="raceCar"
                          class="mb-2"
                        >
                          <p>{{ followUps[5].aliasName }}: </p>
                          <p>{{ followUps[5].questions[0].questionText }}</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Race car options"
                            rules="required"
                          >
                            <v-select
                              v-model="raceCarOptions"
                              :items="followUps[5].questions[0].picklistQuestionMeta.picklistItems"
                              :error-messages="errors"
                              multiple
                              outlined
                            ></v-select>
                          </validation-provider>
                        </div>
                        <div
                          v-if="glider"
                          class="mb-2"
                        >
                          <p>{{ followUps[6].aliasName }}: </p>
                          <p>{{ followUps[6].questions[0].questionText }}</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Glider options"
                            rules="required"
                          >
                            <v-select
                              v-model="gliderOptions"
                              :items="followUps[6].questions[0].picklistQuestionMeta.picklistItems"
                              :error-messages="errors"
                              multiple
                              outlined
                            ></v-select>
                          </validation-provider>
                        </div>
                        <div
                          v-if="racing"
                          class="mb-2"
                        >
                          <p>{{ followUps[7].aliasName }}: </p>
                          <p>{{ followUps[7].questions[0].questionText }}</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Racing options"
                            rules="required"
                          >
                            <v-select
                              v-model="racingOptions"
                              :items="followUps[7].questions[0].picklistQuestionMeta.picklistItems"
                              :error-messages="errors"
                              multiple
                              outlined
                            ></v-select>
                          </validation-provider>
                        </div>
                        <div
                          v-if="cowboy"
                          class="mb-2"
                        >
                          <p>{{ followUps[8].aliasName }}: </p>
                          <p>{{ followUps[8].questions[0].questionText }}</p>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Cowboy options"
                            rules="required"
                          >
                            <v-text-field
                              v-model="cowboyOptions"
                              :error-messages="errors"
                              outlined
                            ></v-text-field>
                          </validation-provider>
                        </div>
                        <div
                          v-if="scuba"
                          class="mb-2"
                        >
                          <p>{{ followUps[9].aliasName }}: </p>
                          <p>{{ followUps[0].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="scubaOptions"
                            mandatory
                          >
                            <v-btn
                              v-for="(answer, i) in followUps[0].questions[0].options"
                              :key="i"
                              color="blanketbutton"
                              text
                              outlined
                              :value="answer"
                            >
                              {{ answer }}
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="skydiver"
                          class="mb-2"
                        >
                          <p>{{ followUps[10].aliasName }}: </p>
                          <p>{{ followUps[10].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="skydiverOptions"
                            mandatory
                          >
                            <v-btn
                              v-for="(answer, i) in followUps[10].questions[0].options"
                              :key="i"
                              color="blanketbutton"
                              text
                              outlined
                              :value="answer"
                            >
                              {{ answer }}
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                        <div
                          v-if="lightFlyer"
                          class="mb-2"
                        >
                          <p>{{ followUps[11].aliasName }}: </p>
                          <p>{{ followUps[11].questions[0].questionText }}</p>
                          <v-btn-toggle
                            v-model="lightFlyerOptions"
                          >
                            <v-btn
                              v-for="(answer, i) in followUps[11].questions[0].options"
                              :key="i"
                              color="blanketbutton"
                              text
                              outlined
                              :value="answer"
                            >
                              {{ answer }}
                            </v-btn>
                          </v-btn-toggle>
                        </div>

                        <p>{{ getquestiontext('smoke_yn') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.smoke_yn"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p v-if="hinterview.smoke_yn ==='true'">
                          {{ getquestiontext('Tobacco') }}
                        </p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Tobacco"
                          rules="required"
                        >
                          <v-select
                            v-if="hinterview.smoke_yn ==='true'"
                            v-model="hinterview.Tobacco"
                            :error-messages="errors"
                            :items="nicUse"
                            label="Select"
                            item-value="code"
                            item-text="description"
                            color="primary"
                            outlined
                          />
                        </validation-provider>
                        <p v-if="hinterview.Tobacco !== 'NONIC' && hinterview.smoke_yn ==='true' ">
                          {{ getquestiontext('nictype') }}
                        </p>
                        <v-combobox
                          v-if="hinterview.Tobacco !== 'NONIC' && hinterview.smoke_yn ==='true'"
                          v-model="nicchoices"
                          :items="niclist"
                          label="Products"
                          multiple
                          outlined
                        ></v-combobox>
                        <p>{{ getquestiontext('MARIJUANA use') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.marijuana"
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('drug use') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.drug_use"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('rehab') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.rehab"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('dui') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.dui"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('crime') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.crime"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('fly') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.fly"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      to="/"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="back"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('term life compare quotes')"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <!--------------------------------STEP 4: HEALTH QUESTIONS-------------------------------->
              <validation-observer
                v-slot="{ invalid }"
                ref="observer"
              >
                <v-stepper-content step="4">
                  <v-card
                    min-height="300"
                    elevation="0"
                  >
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    >
                      Health Questionnaire
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="4"
                        >
                          <v-divider
                            class="test text-center"
                          />
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                    />
                    <v-row class="justify-center">
                      <v-col
                        cols="12"
                        sm="12"
                        md="8"
                      >
                        <p>{{ getquestiontext('primarydoctor_yn') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.primarydoctor_yn"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <v-row v-if="hinterview.primarydoctor_yn === 'true' ">
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <p>Provider Full Name</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Provider Full Name"
                              rules="required"
                            >
                              <v-text-field
                                v-model="healthcareprovider.name"
                                label="Provider Full Name"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <p>Provider Phone</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Provider Phone"
                            >
                              <v-text-field
                                v-model="healthcareprovider.phone"
                                v-mask="'(###)-###-####'"
                                label="Phone Number"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <p>Address</p>
                            <vuetify-google-autocomplete
                              id="map"
                              ref="address"
                              classname="form-control"
                              outlined
                              placeholder="Please type your provider's address"
                              country="us"
                              @placechanged="getMailingAddressData"
                            >
                            </vuetify-google-autocomplete>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Address"
                              rules="required"
                            >
                              <v-text-field
                                v-model="healthcareprovider.address"
                                label="Address"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <p>Date Last Seen</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Date Seen"
                              rules="required"
                            >
                              <v-text-field
                                v-model="healthcareprovider.dateseen"
                                v-mask="'##/##/####'"
                                label="Date last seen"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <p>Reason for visit</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Reason for visit"
                              rules="required"
                            >
                              <v-text-field
                                v-model="healthcareprovider.reason"
                                label="Reason for visit"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <p>Result of visit</p>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Result of visit"
                              rules="required"
                            >
                              <v-text-field
                                v-model="healthcareprovider.result"
                                label="Result of visit"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <p>{{ getquestiontext('height') }}</p>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Height"
                              rules="required|numeric|min_value:1|max_value:8"
                            >
                              <v-text-field
                                v-model="hinterview.feet"
                                label="Feet"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Height"
                              rules="required|numeric|max_value:11"
                            >
                              <v-text-field
                                v-model="hinterview.inches"
                                label="Inches"
                                color="primary"
                                :error-messages="errors"
                                outlined
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <p>{{ getquestiontext('weight') }}</p>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Weight"
                          rules="required|numeric|max_value:600"
                        >
                          <v-text-field
                            v-model="hinterview.weight"
                            color="primary"
                            :error-messages="errors"
                            outlined
                          />
                        </validation-provider>
                        <p>{{ getquestiontext('weight change') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.weight_change"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="weightChange = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="weightChange = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('heart') }}</p>
                        <p>{{ getquestiontext('heartdetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.heart"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="heartDisease = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="heartDisease = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Heart Disease"
                          rules="required"
                        >
                          <v-select
                            v-if="heartDisease"
                            v-model="HEALTH_FOLLOWUP.labelkey['heart']"
                            :error-messages="errors"
                            outlined
                            label="Please select one"
                            :items="getMedicalFollowups('heart disease')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('diabetes') }}</p>
                        <p>{{ getquestiontext('diabetesdetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.diabetes"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="diabetes = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="diabetes = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Diabetes"
                          rules="required"
                        >
                          <v-select
                            v-if="diabetes"
                            v-model="HEALTH_FOLLOWUP.labelkey['diabetes']"
                            outlined
                            label="Please select one"
                            :error-messages="errors"
                            :items="getMedicalFollowups('diabetes')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('blood') }}</p>
                        <p>{{ getquestiontext('blooddetails') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.blood"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="blood = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="blood = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Blood"
                          rules="required"
                        >
                          <v-select
                            v-if="blood"
                            v-model="HEALTH_FOLLOWUP.labelkey['blood']"
                            outlined
                            label="Please select one"
                            :error-messages="errors"
                            :items="getMedicalFollowups('blood')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('lung') }}</p>
                        <p>{{ getquestiontext('lungdetails') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.lung"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="lung = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="lung = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Lung"
                          rules="required"
                        >
                          <v-select
                            v-if="lung"
                            v-model="HEALTH_FOLLOWUP.labelkey['lung']"
                            outlined
                            label="Please select one"
                            :error-messages="errors"
                            :items="getMedicalFollowups('lung')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('cancer') }}</p>
                        <p>{{ getquestiontext('cancerdetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.cancer"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="cancer = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="cancer = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Cancer"
                          rules="required"
                        >
                          <v-select
                            v-if="cancer"
                            v-model="HEALTH_FOLLOWUP.labelkey['cancer']"
                            outlined
                            label="Please select one"
                            :error-messages="errors"
                            :items="getMedicalFollowups('cancer')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('mental') }}</p>
                        <p>{{ getquestiontext('mentaldetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.mental"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="mental = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="mental = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Mental health"
                          rules="required"
                        >
                          <v-select
                            v-if="mental"
                            v-model="HEALTH_FOLLOWUP.labelkey['mental']"
                            outlined
                            label="Please select one"
                            :items="getMedicalFollowups('mental')"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('stomach') }}</p>
                        <p>{{ getquestiontext('stomachdetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.stomach"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="stomach = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="stomach = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Stomach"
                          rules="required"
                        >
                          <v-select
                            v-if="stomach"
                            v-model="hinterview.stomach"
                            outlined
                            label="Please select one"
                            :error-messages="errors"
                            :items="getMedicalFollowups('stomach')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('brain') }}</p>
                        <p>{{ getquestiontext('braindetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.brain"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="brain = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="brain = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Brain"
                          rules="required"
                        >
                          <v-select
                            v-if="brain"
                            v-model="HEALTH_FOLLOWUP.labelkey['brain']"
                            outlined
                            label="Please select one"
                            :items="getMedicalFollowups('brain')"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('bone') }}</p>
                        <p>{{ getquestiontext('bonedetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.bone"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="bone = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="bone = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Bone options"
                          rules="required"
                        >
                          <v-select
                            v-if="bone"
                            v-model="HEALTH_FOLLOWUP.labelkey['bone']"
                            outlined
                            label="Please select one"
                            :error-messages="errors"
                            :items="getMedicalFollowups('bone')"
                          ></v-select>
                        </validation-provider>
                        <p>{{ getquestiontext('doctor') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.doctor"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="doctor = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="doctor = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('surgery') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.surgery"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="surgery = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="surgery = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('prescription') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.prescription"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="prescription = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="prescription = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('hiv') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.hiv"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="hiv = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="hiv = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('history') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.history"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="history = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="history = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <p>{{ getquestiontext('breast kidney') }}</p>
                        <p>{{ getquestiontext('breastkidneydetail') }}</p>
                        <v-btn-toggle
                          v-model="hinterview.breast_kidney"
                          mandatory
                          class="mb-4"
                        >
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="true"
                            @click="breastKidney = true"
                          >
                            Yes
                          </v-btn>
                          <v-btn
                            color="blanketbutton"
                            text
                            outlined
                            value="false"
                            @click="breastKidney = false"
                          >
                            No
                          </v-btn>
                        </v-btn-toggle>
                        <validation-provider
                          v-slot="{ errors }"
                          name="Field"
                          rules="required"
                        >
                          <v-select
                            v-if="breastKidney"
                            v-model="hinterview.breast_kidney"
                            outlined
                            label="Please select one"
                            :items="getMedicalFollowups('breast kidney')"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="pa-2 ma-2">
                    <v-btn
                      text
                      to="/"
                    >
                      Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      @click="back"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      @click="nextquestion('applicant details'); startLoading()"
                    >
                      Continue
                    </v-btn>
                  </v-row>
                </v-stepper-content>
              </validation-observer>
              <!--------------------------------STEP 5 DOCUMENTS-------------------------------->
              <v-stepper-content step="5">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Documents
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                        <v-divider
                          class="test text-center"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-row
                    class="d-flex justify-center"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-card>
                        <v-card-title>
                          Submit answers for processing. Status: Ready - {{ getReadyforSignature }}
                        </v-card-title>
                        <v-card-title>
                          <v-btn
                            color="blanketbutton"
                            dark
                            block
                            class="mb-2"
                            @click="createAppDocuments"
                          >
                            Submit Interview
                          </v-btn>
                        </v-card-title>
                        <v-card-text v-if="CompletedSymetraPDFApplication">
                          Processing Interview
                          <v-progress-linear
                            :active="CompletedSymetraPDFApplication"
                            :indeterminate="CompletedSymetraPDFApplication"
                            absolute
                            color="primary"
                          />
                        </v-card-text>
                        <v-card-text v-if="RenderedApplicationsLoading">
                          Generating PDF Documents
                          <v-progress-linear
                            :active="RenderedApplicationsLoading"
                            :indeterminate="RenderedApplicationsLoading"
                            absolute
                            color="primary"
                          />
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex justify-center"
                  >
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                      class="d-inline-flex align-center"
                    >
                      <v-btn
                        color="blanketbutton"
                        dark
                        block
                        class="mb-2"
                        @click.stop="dialogapplication = true"
                      >
                        View Swift Term Application
                      </v-btn>
                      <v-icon
                        v-if="dialogapplicationAccepted"
                        class="ml-6"
                        color="success"
                        x-large
                      >
                        mdi-check
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-dialog
                        v-model="dialogapplication"
                        width="700"
                      >
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Swift Term Application
                          </v-card-title>

                          <v-card-text>
                            <vue-pdf-embed
                              :source="SymetraICC20LA1"
                            />
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogapplicationAccepted = true; dialogapplication = false"
                            >
                              I accept
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                      class="d-inline-flex align-center"
                    >
                      <v-btn
                        color="blanketbutton"
                        dark
                        block
                        class="mb-2"
                        @click.stop="dialogapplicationLA2 = true"
                      >
                        View Agreement & Signatures
                      </v-btn>
                      <v-icon
                        v-if="dialogapplicationLA2Accepted"
                        class="ml-6"
                        color="success"
                        x-large
                      >
                        mdi-check
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-dialog
                        v-model="dialogapplicationLA2"
                        width="700"
                      >
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Swift Term Application
                          </v-card-title>

                          <v-card-text>
                            <vue-pdf-embed
                              :source="SymetraICC20LA2"
                            />
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogapplicationLA2Accepted = true; dialogapplicationLA2 = false"
                            >
                              I accept
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                      class="d-inline-flex align-center"
                    >
                      <v-btn
                        color="blanketbutton"
                        dark
                        block
                        class="mb-2"
                        @click.stop="dialogapplicationLA3 = true"
                      >
                        View Personal Information Release
                      </v-btn>
                      <v-icon
                        v-if="dialogapplicationLA3Accepted"
                        class="ml-6"
                        color="success"
                        x-large
                      >
                        mdi-check
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-dialog
                        v-model="dialogapplicationLA3"
                        width="700"
                      >
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            Release of Personal Information
                          </v-card-title>

                          <v-card-text>
                            <vue-pdf-embed
                              :source="SymetraICC20LA3"
                            />
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogapplicationLA3Accepted = true; dialogapplicationLA3 = false"
                            >
                              I accept
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                      class="d-inline-flex align-center"
                    >
                      <v-btn
                        color="blanketbutton"
                        dark
                        block
                        class="mb-2"
                        @click.stop="dialogLU820 = true"
                      >
                        View HIPPA Release
                      </v-btn>
                      <v-icon
                        v-if="dialogLU820Accepted"
                        class="ml-6"
                        color="success"
                        x-large
                      >
                        mdi-check
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      lg="6"
                    >
                      <v-dialog
                        v-model="dialogLU820"
                        width="700"
                      >
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            HIPPA Release
                          </v-card-title>

                          <v-card-text>
                            <vue-pdf-embed
                              :source="SymetraLU820"
                            />
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              text
                              @click="dialogLU820Accepted = true; dialogLU820 = false"
                            >
                              I accept
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col
                      v-if="getReadyforSignature"
                      cols="12"
                      sm="6"
                      md="6"
                      lg="8"
                    >
                      <p class="text-center text-h5 mb-6 mt-4 black--text">
                        Please review and sign each document.
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
                <v-row class="pa-2 ma-2">
                  <v-btn
                    text
                    to="/"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    @click="back"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!acceptDocuments"
                    @click="kickoutOrContinue()"
                  >
                    Continue
                  </v-btn>
                </v-row>
              </v-stepper-content>
              <!---------------------------STEP 6 LOADING/CONGRATS-------------------------------->
              <v-stepper-content step="6">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    v-if="element1Visible"
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Communicating with Symetra. Processing your application...
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                        <v-progress-linear
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="element2Visible"
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Processing decision information...
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                        <v-progress-linear
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="element3Visible"
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    <v-img
                      height="200"
                      contain
                      src="/img/logo.svg"
                    />
                    <div class="mb-6">
                      Congratulations!!
                    </div>
                    <div>
                      Please check your email to complete the last step and get covered under the blanket today!
                    </div>
                    <br>
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
                <v-row
                  v-if="element3Visible"
                  class="pa-2 ma-2"
                >
                  <v-btn
                    text
                    to="/"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    @click="back"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextquestion('applicant details')"
                  >
                    Continue
                  </v-btn>
                </v-row>
              </v-stepper-content>
              <!---------------------------STEP 7 DEV-------------------------------->
              <v-stepper-content step="7">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    <!-- <p v-if="getpoicydetails">life id {{ getpoicydetails.symetra.lifeId }} case id {{ getpoicydetails.symetra.casedata.caseid }} policy id {{ getpoicydetails.symetra.policyid }}</p> -->
                    <v-btn
                      @click="createLifeId"
                    >
                      Create Life ID
                    </v-btn>
                    <v-btn
                      @click="createCaseId"
                    >
                      Create Case ID
                    </v-btn>
                    <v-btn
                      @click="createPolicy"
                    >
                      Create Policy
                    </v-btn>
                    <v-btn
                      @click="createTermLife"
                    >
                      Create Term Life
                    </v-btn>
                    <v-btn
                      @click="sendTermLifeData"
                    >
                      Submit Life Data
                    </v-btn>

                    <v-btn
                      @click="sendInterview"
                    >
                      Send Interview
                    </v-btn>
                    <p
                      class="text-center text-h5 mb-6 mt-4 black--text"
                    >
                      <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        striped
                        absolute
                        color="primary"
                      />
                    </p>
                  </div>
                </v-card>
                <v-row class="pa-2 ma-2">
                  <v-btn
                    text
                    to="/"
                  >
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    @click="back"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="endtermsurvey"
                  >
                    Continue
                  </v-btn>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <div class="mb-16">
    </div>
  </v-container>
</template>

<script>
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  import moment from 'moment'
  var numeral = require('numeral')
  var states = require('ustates')

  export default {
    // bday en Your birth date is:
    // height en What is your height?
    // weight in lbs en What is your weight in pounds?
    // nic en In the past 5 years have you used any form of tobacco or nicotine (including e-cigs, patches or gum)?
    name: 'TermCoverage',
    components: {
      VuePdfEmbed
    },
    props: ['id'],
    // <----------------------------------------DATA---------------------------------------->
    data: () => ({
      pbSameAddress: [],
      cbSameAddress: [],
      percentageErrorPB: false,
      percentageErrorCB: false,
      adbAmount: '',
      designateContingent: '',
      shareEquallyCB: false,
      numberOfCB: '',
      cbPercentage: '',
      age: '',
      pbPercentage: '',
      shareEquallyPB: false,
      numberOfPB: '',
      numberOfPBList: [1, 2, 3, 4, 5],
      beneficiaryRelationshipList: ['Spouse/Domestic Partner', 'Parent', 'Sibling', 'Child', 'Other – Individual', 'Trust', 'Estate', 'Business Partner'],
      hinterview: {
        applicantdrives: '',
        blood: '',
        bone: '',
        brain: '',
        breast_kidney: '',
        cancer: '',
        crime: '',
        diabetes: '',
        doctor: '',
        drug_use: '',
        dui: '',
        fly: '',
        heart: '',
        history: '',
        hiv: '',
        lung: '',
        mental: '',
        prescription: '',
        primarydoctor_yn: '',
        rehab: '',
        stomach: '',
        surgery: '',
        weight_change: ''
      },
      primaryBeneficiaries: [
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              percentageToRecieve: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
      ],
      contingentBeneficiaries: [
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
        {
          relationshipToInsured: '',
          percentageToRecieve: '',
          sameAddress: '',
          individual:
            {
              firstName: '',
              middleInitial: '',
              lastName: '',
              suffix: '',
              dateOfBirth: '',
              age: '',
              ssn: '',
              residentialAddress: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
              bDay: '',
              bMonth: '',
              bYear: '',
            },

          organization:
            {
              name: '',
              established: '',
              tin: '',
              address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: ''
              },
              phone: '',
              email: '',
            }
        },
      ],
      childAgeError: false,
      ctr_children: [
        {
          id: 1,
          firstName: '',
          middleInitial: '',
          lastName: '',
          suffix: '',
          bDay: '',
          bMonth: '',
          bYear: '',
          amount: '',
          ssn: '',
          gender: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            zip: '',

          }
        },
        {
          id: 2,
          firstName: '',
          middleInitial: '',
          lastName: '',
          suffix: '',
          bDay: '',
          bMonth: '',
          bYear: '',
          amount: '',
          ssn: '',
          gender: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            zip: '',

          }
        },
        {
          id: 3,
          firstName: '',
          middleInitial: '',
          lastName: '',
          suffix: '',
          bDay: '',
          bMonth: '',
          bYear: '',
          amount: '',
          ssn: '',
          gender: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            zip: '',

          }
        },
        {
          id: 4,
          firstName: '',
          middleInitial: '',
          lastName: '',
          suffix: '',
          bDay: '',
          bMonth: '',
          bYear: '',
          amount: '',
          ssn: '',
          gender: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            zip: '',

          }
        },
        {
          id: 5,
          firstName: '',
          middleInitial: '',
          lastName: '',
          suffix: '',
          bDay: '',
          bMonth: '',
          bYear: '',
          amount: '',
          ssn: '',
          gender: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            zip: '',

          }
        }
      ],
      ctr_amounts: [],
      ctr_number: '',
      ctr_numbers: [1, 2, 3, 4, 5],
      ctr_future: 'No',
      ctrFuture_name: 'For a future child',
      ctrFuture_amount: '',
      ctrAmounts: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
      healthcareprovider: {
        name: '',
        address: '',
        dateseen: '',
        reason: '',
        result: '',
        phone: ''
      },
      acceptTerms: false,
      specialStates: ['CA', 'DE', 'DC', 'FL', 'GA', 'ID', 'IL', 'IN', 'KS', 'KY', 'MA', 'MI', 'MN', 'NV', 'ND', 'OK', 'PA', 'TN', 'WA', 'WY'],
      notifyInsurer: true,
      applicantCertifyMI: '',
      applicantCertifyMN: '',
      approvedSalesMaterials: '',
      leaveSalesMaterial: false,
      reasonForReplacement: '',
      useExistingFunds: true,
      consideringDiscontinuing: true,
      georgiaSummaryStatement: '',
      companies: ['Company 1', 'Company 2', 'Company 3'],
      policyNums: [1, 2, 3, 4, 5],
      policyNumSelect: '',
      numExisitingPolicies: 0,
      currentLifeInsurance: '',
      currentLifeInsuranceAmount: '',
      existingCompanyInsurance: '',
      existingPolicyType: 'Policy Type 1',
      existingPolicyTypes: ['Policy Type 1', 'Policy Type 2', 'Policy Type 3'],
      existingPolicies: [
        {
          companyName: '',
          radioButtons: [],
          textboxes: [],
          addresses: [],
          dates: []
        },
        {
          companyName: '',
          radioButtons: [],
          textboxes: [],
          addresses: [],
          dates: []
        },
        {
          companyName: '',
          radioButtons: [],
          textboxes: [],
          addresses: [],
          dates: []
        },
        {
          companyName: '',
          radioButtons: [],
          textboxes: [],
          addresses: [],
          dates: []
        },
        {
          companyName: '',
          radioButtons: [],
          textboxes: [],
          addresses: [],
          dates: []
        },

      ],
      adb: false,
      acdb: false,
      wop: false,
      ctr: false,
      ca1: false,
      ca2: '',
      ca3: false,
      ca4: false,
      ca5: false,
      ca6: '',
      suffixes: ['JR', 'SR', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'MD', 'DDS', 'DVD', 'ESQ', 'DO', 'DMD', 'PhD'],
      element1Visible: true,
      element2Visible: false,
      element3Visible: false,
      weightChange: true,
      heartDisease: true,
      diabetes: true,
      blood: true,
      lung: true,
      mental: true,
      cancer: true,
      stomach: true,
      brain: true,
      bone: true,
      doctor: true,
      surgery: true,
      prescription: true,
      hiv: true,
      history: true,
      breastKidney: true,
      healthFollowups: {
        weightChange: ['option1', 'option2', 'option3'],

      },
      dialogapplication: false,
      dialogapplicationAccepted: false,
      dialogapplicationLA2: false,
      dialogapplicationLA2Accepted: false,
      dialogapplicationLA3: false,
      dialogapplicationLA3Accepted: false,
      dialogLU820: false,
      dialogLU820Accepted: false,
      hippaAck: false,
      infoRelAck: false,
      eDeliveryAck: false,
      uwProcessAck: false,
      privNoticeAck: false,
      noticeOfInsPracticesAck: false,
      lifeidAck: false,
      motorVehicles: [],
      baseJumpingOptions: '',
      fightingOptions: '',
      bungeeOptions: '',
      caverOptions: '',
      climberOptions: '',
      raceCarOptions: [],
      gliderOptions: [],
      racingOptions: [],
      cowboyOptions: '',
      scubaOptions: '',
      skydiverOptions: '',
      lightFlyerOptions: '',
      hobbies: [],
      applicantdrives: '',
      dl_state: '',
      dl_number: '',
      insurredispolicyowner: 'true',
      dialogack: true,
      nicchoices: [],
      e1: 1,
      f6: 1,
      termlength: '',
      testval: 'false',
      providerloading: false,
      policyvalue: '',
      provider: '',
      present: [
        v => !!v || 'Field is required',
      ],
      numeric_only: [
        v => !!v || 'This field is required',
        v => /^[0-9]*$/.test(v) || 'Numeric values only',
      ],
      HEALTH_INTERVIEW: {
        labelkey: []
      },
      HEALTH_FOLLOWUP: {
        labelkey: []
      },
      noticelist: [
        {
          name: 'HIPAA',
          url: '',
          ack: '',
          detail: 'The form authorizes any physician, hospital, pharmacy, pharmacy benefit manager, health insurance plan or any other entity that possesses any diagnosis, treatment, prescription or other medical information about me to furnish such health information to Symetra Life Insurance Company for the purpose of evaluating my eligibility for insurance. This medical or health information may include information on the diagnosis and treatment of mental illness, alcohol, and drug use. This also includes information on the diagnosis, treatment, and testing results related to HIV, AIDS, and sexually transmitted diseases, unless otherwise restricted by state law. Health information obtained will not be re-disclosed without my authorization unless permitted by law, in which case it may not be protected under federal privacy rules. This authorization shall be valid for two years from this date and may be revoked by sending written notice to Symetra Life Insurance Company.'
        },
        { name: 'eSignature & eDelivery Consent form', url: '', ack: '', detail: 'This form acknowledges your consent to transact business with Symetra Life Insurance Company and its affiliates and third-party service providers through electronic communications and, in some instances, using electronic signatures. You also consent to using eDelivery for any policy-related documents.' },
        { name: 'Information around underwriting processes', url: '', ack: '', detail: 'This form highlights the information we collect to determine your eligibility for insurance based on our risk criteria, including (but not limited to): Your age, occupation, finances, physical condition, health history, mode of living, avocations, and other personal characteristics.' },
        { name: 'Notice of Insurance Information Practices', url: '', ack: '', detail: 'This form authorizes Symetra Life Insurance Company to request medical, non-medical, employment and insurance information from outside parties, including (but not limited to): Medical care providers and/or physicians, health insurance record holders, motor vehicle departments, credit or consumer reporting agencies, etc.' },
        { name: 'Authorization to release personal information', url: '', ack: '', detail: 'This form authorizes Symetra Life Insurance Company to request medical, non-medical, employment and insurance information from outside parties, including (but not limited to): Medical care providers and/or physicians, health insurance record holders, motor vehicle departments, credit or consumer reporting agencies, etc.' },
        { name: 'Privacy Notice', url: '', ack: '', detail: 'This form explains how we use and protect your information. Symetra Life Insurance Company is serious about keeping your personal information private and secure. We do not sell or rent information about you to others.' },
      ],
      LIFE_INTERVIEW: {
        LA2_EMPLOYMENT_TYPE: '',
        LA2_WORK_TYPE: '',
        LA2_HOURSWORK: '',
        LA2_ARMED_FORCES: '',
        LA2_DISABLED: '',
        LA2_ANN_INCOME: '',
        LA2_ANN_HOUSEHOLD_INCOME: '',
        LA2_NET_WORTH: '',
        LA2_BANKRUPTCY: '',
        LA2_INSURE_NEED: '',
        LA2_APPLIED_FOR: '',
        LA2_INSURANCE_DECLINED: '',
        LA2_EXERCISE: '',
        LA2_TOBACCO_USE: '',
        LA2_MARIJUANA: '',
        LA2_DRUGS_USE: '',
        LA2_ALCOHOL_DRUG_TREATMENT: '',
        LA2_DUI: '',
        LA2_FELONY: '',
        LA2_MOVING_VIOLATION: '',
        LA2_DL_REVOKED: '',
        LA2_AVOCATION: '',
        LA2_AVIATION: '',
        LA2_HEIGHT: '',
        LA2_WEIGHT: '',
        LA2_WEIGHT_CHANGE: '',
        LA2_HEART: '',
        LA2_DIABETES: '',
        LA2_BLOOD_DISORDERS: '',
        LA2_LUNG: '',
        LA2_CANCER: '',
        LA2_MENTAL_NERVOUS: '',
        LA2_DIGESTIVE: '',
        LA2_BRAIN_MUSCLE: '',
        LA2_BONE_MUSCLE: '',
        LA2_CONSULT_MD: '',
        LA2_MD_TREATMENT_SURGERY: '',
        LA2_RX: '',
        LA2_AIDS_HIV: '',
        LA2_FAMILY_HISTORY: '',
        LA2_BREAST_KIDNEY: '',
      },
      policyoptions: {
        termlength: '',
        policyvalue: '',
        provider: [],
        policytype: 'TermLife',
        monthlypremium: ''
      },
      selected: [],
      offers: [
        {
          name: 'Symetra',
          cost: '$159.00',
          medical: 'No',
        },
        {
          name: 'Lincoln A',
          cost: '$159.00',
          medical: 'Yes',
        },
        {
          name: 'Protective',
          cost: '$159.00',
          medical: 'No',
        },
      ],
      currentuser: {
        email: '',
        ssn: '',
        id: '',
        alerts: '',
        status: '',
        firstname: '',
        lastname: '',
        suffix: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
        state: '',
        gender: '',
        bday: '',
        bmonth: '',
        byear: '',
        age: '',
      },
      owner: {
        firstName: '',
        middleInitial: '',
        lastName: '',
        suffix: '',
        bday: '',
        bmonth: '',
        byear: '',
        dateOfBirth: '',
        age: '',
        ssn: '',
        gender: '',
        email: '',
        relationshipToInsured: '',
        residentialAddress: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          zip: ''
        },
        mobile: '',
        alternatePhone: ''
      },
      userhealth: {

      },
      useracttivities: {

      },
      userhistory: {

      },
      max: 50,
      allowSpaces: true,
      employment_options: [
        {
          answerText: 'Paid employee or self-employed',
          answerValue: 'PAID_EMPOLYMENT',
        },
        {
          answerText: 'Student',
          answerValue: 'STUDENT',
        },
        {
          answerText: 'Unemployed',
          answerValue: 'UNEMPLOYED',
        },
        {
          answerText: 'Homemaker or stay-at-home parent',
          answerValue: 'HOMEMAKER',
        },
        {
          answerText: 'Retired',
          answerValue: 'RETIRED',
        },
        {
          answerText: 'Unknown',
          answerValue: '0',
        },
      ],
      activitylist: [
        'BASE jumping',
        'Boxing or fighting including kickboxing, UFC and MMA',
        'Bungee jumping',
        'Cave exploration',
        'Climbing',
        'Motor vehicle racing',
        'Paragliding or hang gliding',
        'Racing',
        'Rodeo',
        'Scuba diving',
        'Skydiving',
        'Ultralight flying',
        'None of the above'
      ],
      maritallist: [
        'Married',
        'Married/Partners to a Civil Union',
        'Single',
        'Divorced',
        'Separated',
        'Widowed',
      ],
      niclist: [
        'Chew or snuff',
        'Cigar',
        'Cigarettes',
        'E-Cigarettes',
        'Pipe, hookah or water pipe',
        'Vaporizers',
        'Other nicotine or tobacco products'
      ],
      relationToInsuredList: [
        'Spouse/Domestic Partner',
        'Child',
        'Parent',
        'Sibling',
        'Grandparent',
        'Business Partner'
      ],
      relationshiplist: [
        {
          code: 'OLI_REL_SPOUSE',
          description: 'Spouse',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_CHILD',
          description: 'Child',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_BUSINESS',
          description: 'Business',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_BUSINESSASSOCIATE',
          description: 'Business Associate',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_DOMPART',
          description: 'Domestic Partner',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'CHARITY',
          description: 'Charity',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_EMPLOYER',
          description: 'Employer',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'INSURED_ESTATE',
          description: 'Estate of Insured',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_FIANCEE',
          description: 'Fiancee',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_GRANDCHILD',
          description: 'Grand Child',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_GRANDPARENT',
          description: 'Grand Parent',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_LEGALGUARD',
          description: 'Guardian',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_PARENT',
          description: 'Parent',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_SIBLING',
          description: 'Sibling',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_STEPCHILD',
          description: 'Step Child',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_STEPPARENT',
          description: 'Step Parent',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'TRUST',
          description: 'Trust',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OTHER_RELATIVE',
          description: 'Other Relative',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_OTHER',
          description: 'Other',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_AUTHORIZEDPERSON',
          description: 'Authorized Person',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_PARTNER',
          description: 'Business Partner',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_CONTGNTBENE',
          description: 'Contingent Beneficiary',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_REL_INSURED',
          description: 'Same as Insured',
          listName: 'InsurableInterestRelationship'
        },
        {
          code: 'OLI_UNKNOWN',
          description: 'Unknown',
          listName: 'InsurableInterestRelationship'
        },
      ],
      fundsList: [
        {
          code: 'SAVINGS',
          description: 'Savings',
          listName: 'Sourceoffunds'
        },
        {
          code: 'Other',
          description: 'Other',
          listName: 'Sourceoffunds'
        },
        {
          code: 'CURRENTINCOME',
          description: 'Current income',
          listName: 'Sourceoffunds'
        },
        {
          code: '0',
          description: 'unknown',
          listName: 'Sourceoffunds'
        },
      ],
      netWorth: [
        {
          code: 'LESS_THAN_100000',
          description: 'Less than $100,000',
          listName: 'NetWorth'
        },
        {
          code: '$100,001-$250,000',
          description: '$100,001- $250,000',
          listName: 'NetWorth'
        },
        {
          code: '$250,001-$500,000',
          description: '$250,001-$500,000',
          listName: 'NetWorth'
        },
        {
          code: '$500,001-$750,000',
          description: '$500,001-$750,000',
          listName: 'NetWorth'
        },
        {
          code: '$750,001-$1,000,000',
          description: '$750,001-$1,000,000',
          listName: 'NetWorth'
        },
        {
          code: '1,000,001_and_up',
          description: '$1,000,001 and up',
          listName: 'NetWorth'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'NetWorth'
        },
      ],
      Income: [
        {
          code: 'Less_than_$25,000',
          description: 'Less than $25,000',
          listName: 'Income'
        },
        {
          code: '$25,001-$50,000',
          description: '$25,001-$50,000',
          listName: 'Income'
        },
        {
          code: '$50,001-$75,000',
          description: '$50,001-$75,000',
          listName: 'Income'
        },
        {
          code: '$75,001-$100,000',
          description: '$75,001-$100,000',
          listName: 'Income'
        },
        {
          code: '$100,001-$150,000',
          description: '$100,001-$150,000',
          listName: 'Income'
        },
        {
          code: '$150,001-$200,000',
          description: '$150,001-$200,000',
          listName: 'Income'
        },
        {
          code: '$200,001-$250,000',
          description: '$200,001-$250,000',
          listName: 'Income'
        },
        {
          code: '$250,001_and_up',
          description: '$250,001 and up',
          listName: 'Income'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'Income'
        },
      ],
      drivingStatus: [
        {
          code: 'NEVERNEEDED',
          description: 'Never needed one',
          listName: 'NoDL'
        },
        {
          code: 'REVOKED',
          description: 'Has one but it was revoked',
          listName: 'NoDL'
        },
        {
          code: 'NEVERRENEW',
          description: 'Never choose to renew',
          listName: 'NoDL'
        },
        {
          code: 'NOTABLETOGET',
          description: 'Was not able to get one',
          listName: 'NoDL'
        },
        {
          code: 'OTHER',
          description: 'Other',
          listName: 'NoDL'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'NoDL'
        },
      ],
      movingViolations: [
        {
          code: 'NONE',
          description: 'None',
          listName: 'Numofmovingviolation'
        },
        {
          code: 'LESSTHAN2',
          description: '1-2',
          listName: 'Numofmovingviolation'
        },
        {
          code: 'LESSTHAN5',
          description: '3-5',
          listName: 'Numofmovingviolation'
        },
        {
          code: 'MORETHAN6',
          description: '6 or more',
          listName: 'Numofmovingviolation'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'Numofmovingviolation'
        },
      ],
      insuranceNeed: [
        {
          code: 'PERSONAL',
          description: 'Personal',
          listName: 'Insurance need'
        },
        {
          code: 'FAMILY_PROTECTION',
          description: 'Family protection',
          listName: 'Insurance need'
        },
        {
          code: 'INCOME_REPLACEMENT',
          description: 'Income replacement',
          listName: 'Insurance need'
        },
        {
          code: 'OTHER',
          description: 'Other',
          listName: 'Insurance need'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'Insurance need'
        },
      ],
      nicUse: [
        {
          code: 'NONIC',
          description: 'Never used',
          listName: 'TOBACCOUSE2'
        },
        {
          code: 'NIC12',
          description: 'Within the last 12 months',
          listName: 'TOBACCOUSE2'
        },
        {
          code: 'NIC36',
          description: '13-36 months',
          listName: 'TOBACCOUSE2'
        },
        {
          code: 'NIC60',
          description: '37-60 months',
          listName: 'TOBACCOUSE2'
        },
        {
          code: 'NIC60PLUS',
          description: '61 months or greater',
          listName: 'TOBACCOUSE2'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'TOBACCOUSE2'
        },
      ],
      hoursWorked: [
        {
          code: '0-10',
          description: '0-10',
          listName: 'Hours Worked'
        },
        {
          code: '11-20',
          description: '11-20',
          listName: 'Hours Worked'
        },
        {
          code: '21-30',
          description: '21-30',
          listName: 'Hours Worked'
        },
        {
          code: '31-40',
          description: '31-40',
          listName: 'Hours Worked'
        },
        {
          code: '41_or_ more',
          description: '41 or more',
          listName: 'Hours Worked'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'Hours Worked'
        },
      ],
      unique: true,
      quotekey: '',
      activePicker: 'YEAR',
      date: null,
      menu: false,
      filterchars: true,
      onlyspace: false,
      loadedsites: '',
      policyvalues: [
        '$250k',
        '$500k',
        '$750k',
        '$1M',
        '$2M',
      ],
      policyamounts: [
        250000,
        500000,
        750000,
        1000000,
        2000000
      ],

    }),
    // <--------------------------------------COMPUTED PROPERTIES-------------------------------------->
    computed: {
      StateList () {
        let statelist = Object.values(states)
        statelist = statelist.slice(0, 51)
        let list = statelist.map(entry => entry.name)
        console.log(list)
        return list
      },
      calcage () {
        let age
        if (this.byear && this.bmonth && this.bday) {
          let date = moment(this.byear + '-' + this.bmonth + '-' + this.bday)
          age = moment().diff(date, 'years')
        }
        return age
      },
      calcbday () {
        let dateofbirth = '-'
        let day = this.owner.bday
        let month = this.owner.bmonth
        let year = this.owner.byear
        if (day && month && year) {
          dateofbirth = moment(month + '-' + day + '-' + year).format('MM-DD-YYYY')
          this.owner.dateOfBirth = dateofbirth
        } else {
          dateofbirth = '-'
        }
        return dateofbirth
      },
      nearestAge () {
        let birthdate = this.currentuser.dob
        let year = moment(birthdate).format('YYYY')
        let month = moment(birthdate).format('MM')
        let day = moment(birthdate).format('DD')
        let clientage = moment().diff(moment(birthdate, 'YYYY-MM-DD'), 'years')

        let nearestAge = clientage
        let currentyear = moment().format('YYYY')
        let eventdate = moment(currentyear + '-' + month + '-' + day).add(6, 'months')
        let todaysdate = moment()
        console.log('event date ', eventdate.format('YYYY-MM-DD'), ' days to next bday ')
        if (todaysdate.isAfter(eventdate)) {
          console.log('advance their age a year')
          nearestAge = clientage + 1
          console.log('adding one to age so nearest age is ', nearestAge)
        }

        console.log('nearest age is ', nearestAge)
        return nearestAge
      },
      faceAmountArray () {
        return [{ faceamount: this.ctr_children[0].amount }, { faceamount: this.ctr_children[1].amount }, { faceamount: this.ctr_children[2].amount }, { faceamount: this.ctr_children[3].amount }, { faceamount: this.ctr_children[4].amount }]
      },
      ICBPrices () {
        let rates = this.$store.getters.getICBRates
        return rates
      },
      WOPPrices () {
        let rates = this.$store.getters.getWOPRates
        return rates
      },
      ADBPrices () {
        let rates = this.$store.getters.getADBRates
        return rates
      },
      yearsavailable () {
        let maxyear = Number(moment().format('YYYY')) + 1
        let startingyear = Number(moment().subtract(18, 'years').format('YYYY'))
        let years = []
        for (let i = startingyear; i < maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      yearsavailable2 () {
        let maxyear = Number(moment().format('YYYY')) + 1
        let startingyear = Number(moment().subtract(100, 'years').format('YYYY'))
        let years = []
        for (let i = startingyear; i < maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      pdfsloading () {
        return this.$store.getters.getnoticesloading
      },
      RenderedApplicationsLoading () {
        return this.$store.getters.getRenderedApplicationsLoading
      },
      CompletedSymetraPDFApplication () {
        return this.$store.getters.getCompletedSymetraPDFApplication
      },
      getReadyforSignature () {
        return this.$store.getters.getReadyforSignature
      },
      acceptDocuments () {
        if (this.dialogapplicationAccepted && this.dialogapplicationLA2Accepted && this.dialogapplicationLA3Accepted && this.dialogLU820Accepted) {
          // lets record all our data here also in the db
          return true
        } else {
          return false
        }
      },
      SymetraICC20LA1 () {
        let file = ''
        file = this.$store.getters.getSymetraICC20LA1
        console.log('$$$$$ our pdf file is ', file)
        return file
      },
      SymetraICC20LA2 () {
        //  ICC20LA2Signature_mitchell_rankin.pdf
        let file = ''
        file = this.$store.getters.getSymetraICC20LA2
        console.log('$$$$$ our pdf file is ', file)
        return file
      },
      SymetraICC20LA3 () {
        //  ICC20LA2Signature_mitchell_rankin.pdf
        let file = ''
        file = this.$store.getters.getSymetraICC20LA3
        console.log('$$$$$ our pdf file is ', file)
        return file
      },
      SymetraLU820 () {
        //  ICC20LA2Signature_mitchell_rankin.pdf
        let file = ''
        file = this.$store.getters.getSymetraLU820
        console.log('$$$$$ our pdf file is ', file)
        return file
      },
      noticeListFiles () {
        let cloudNotices = []
        cloudNotices = this.$store.getters.getSymetraPdfs
        console.log('cloudNotices', cloudNotices)
        // "https://firebasestorage.googleapis.com/v0/b/blanket-development.appspot.com/o/symetra%2F39states%2FSYM%20-%201013.1.pdf?alt=media&token=c22f6f23-bee4-4123-b421-ac75a52108f5"
        let consumerPrivacyKey = encodeURI('SYM_1013_0222.pdf')
        let privacyNotice = ''
        let UwProcessKey = encodeURI('LU-1115.pdf')
        let UwProcessNotice = ''
        let electronicDisclosuresKey = encodeURI('LU-1111.pdf')
        let electronicDisclosure = ''
        let infoPractKey = encodeURI('ICC20LA4.pdf')
        let infoPractNotice = ''
        let infoReleaseKey = encodeURI('ICC20LA3.pdf')
        let infoReleaseNotice = ''
        let hippaKey = encodeURI('LU-820 Hipaa Authorization.pdf')
        let hippaNotice = ''

        // pa specific
        // PA_LU_453
        let pa_disclosure_key = encodeURI('PA_LU_453.pdf')
        let pa_disclosure = ''

        console.log('url is ', consumerPrivacyKey)
        cloudNotices.forEach(entry => {
          let priv = entry.includes(consumerPrivacyKey)
          let uwprocess = entry.includes(UwProcessKey)
          let eledisc = entry.includes(electronicDisclosuresKey)
          let infoprac = entry.includes(infoPractKey)
          let inforelease = entry.includes(infoReleaseKey)
          let hippa = entry.includes(hippaKey)
          let pa_disc = entry.includes(pa_disclosure_key)
          if (pa_disc) {
            pa_disclosure = entry
          }
          if (priv) {
            privacyNotice = entry
          }
          if (uwprocess) {
            UwProcessNotice = entry
          }
          if (eledisc) {
            electronicDisclosure = entry
          }
          if (infoprac) {
            infoPractNotice = entry
          }
          if (inforelease) {
            infoReleaseNotice = entry
          }
          if (hippa) {
            hippaNotice = entry
          }
        })

        let files = [
          {
            name: 'HIPAA',
            url: hippaNotice,
            ack: '',
            detail: 'The form authorizes any physician, hospital, pharmacy, pharmacy benefit manager, health insurance plan or any other entity that possesses any diagnosis, treatment, prescription or other medical information about me to furnish such health information to Symetra Life Insurance Company for the purpose of evaluating my eligibility for insurance. This medical or health information may include information on the diagnosis and treatment of mental illness, alcohol, and drug use. This also includes information on the diagnosis, treatment, and testing results related to HIV, AIDS, and sexually transmitted diseases, unless otherwise restricted by state law. Health information obtained will not be re-disclosed without my authorization unless permitted by law, in which case it may not be protected under federal privacy rules. This authorization shall be valid for two years from this date and may be revoked by sending written notice to Symetra Life Insurance Company.'
          },
          { name: 'eSignature & eDelivery Consent form', url: electronicDisclosure.toString(), ack: '', detail: 'This form acknowledges your consent to transact business with Symetra Life Insurance Company and its affiliates and third-party service providers through electronic communications and, in some instances, using electronic signatures. You also consent to using eDelivery for any policy-related documents.' },
          { name: 'Information around underwriting processes', url: UwProcessNotice, ack: '', detail: 'This form highlights the information we collect to determine your eligibility for insurance based on our risk criteria, including (but not limited to): Your age, occupation, finances, physical condition, health history, mode of living, avocations, and other personal characteristics.' },
          { name: 'Notice of Insurance Information Practices', url: infoPractNotice, ack: '', detail: 'This form authorizes Symetra Life Insurance Company to request medical, non-medical, employment and insurance information from outside parties, including (but not limited to): Medical care providers and/or physicians, health insurance record holders, motor vehicle departments, credit or consumer reporting agencies, etc.' },
          { name: 'Authorization to release personal information', url: infoReleaseNotice, ack: '', detail: 'This form authorizes Symetra Life Insurance Company to request medical, non-medical, employment and insurance information from outside parties, including (but not limited to): Medical care providers and/or physicians, health insurance record holders, motor vehicle departments, credit or consumer reporting agencies, etc.' },
          { name: 'Privacy Notice', url: privacyNotice.toString(), ack: '', detail: 'This form explains how we use and protect your information. Symetra Life Insurance Company is serious about keeping your personal information private and secure. We do not sell or rent information about you to others.' },
        ]
        // if state is PA
        // add
        // { name: 'Disclosures in the solicitation of life Insurance', url: pa_disclosure.toString(), ack: '', detail: 'Pennsylvania Regulations require that a Disclosure Statement be completed and given to the Applicant of a life insurance policy prior to taking an application.' },

        return files
      },
      followUps () {
        let followUps = this.$store.getters.getSymetraFollowUps.followupQuestions
        console.log('followUps', followUps)
        return followUps
      },
      validdoccheck () {
        let valid = false
        let choices = [
          this.hippaAck,
          this.infoRelAck,
          this.eDeliveryAck,
          this.uwProcessAck,
          this.privNoticeAck,
          this.noticeOfInsPracticesAck
        ]
        console.log('doc list ', choices)
        if (choices.every(choice => choice === true)) {
          console.log('TRUE AND VALID')
          return valid = true
        }
        return valid
      },
      loading () {
        return this.$store.getters.getloading
      },
      getproviders () {
        let providers = ''
        providers = this.$store.getters.getProviderRecommendations
        console.log('we have provider data ', providers)
        return providers
      },
      getpolicyvalue () {
        return numeral(this.policyamounts[this.policyvalue]).format('$0,000')
      },
      getpolicydetails () {
        return this.$store.getters.getcurrentquote
      },
      basejumper () {
        return this.hobbies.includes('BASE jumping')
      },
      fighter () {
        return this.hobbies.includes('Boxing or fighting including kickboxing, UFC and MMA')
      },
      bungee () {
        return this.hobbies.includes('Bungee jumping')
      },
      caver () {
        return this.hobbies.includes('Cave exploration')
      },
      climber () {
        return this.hobbies.includes('Climbing')
      },
      raceCar () {
        return this.hobbies.includes('Motor vehicle racing')
      },
      glider () {
        return this.hobbies.includes('Paragliding or hang gliding')
      },
      racing () {
        return this.hobbies.includes('Racing')
      },
      cowboy () {
        return this.hobbies.includes('Rodeo')
      },
      scuba () {
        return this.hobbies.includes('Scuba diving')
      },
      skydiver () {
        return this.hobbies.includes('Skydiving')
      },
      lightFlyer () {
        return this.hobbies.includes('Ultralight flying')
      },
      occupations () {
        let occupations = this.$store.getters.getSymetraOccupations
        let list = []
        occupations.forEach(item => list.push(item.occupation))
        return list
      },
      questions () {
        console.log('APP QUESTIONS', this.appQuestions)
        return true
      },
      medicalFollowups () {
        return this.$store.getters.getSymetraMedicalFollowups
      },
      stateData () {
        let data = this.$store.getters.getSymetraReplacementData
        let stateData = data.filter(item => item.state === this.currentuser.state)
        return stateData
      },
      existingInsuranceDropdowns () {
        let arr = []
        this.stateData[0].existingInsurance.forEach(item => {
          if (item.type === 'Dropdown') {
            arr.push(item)
          }
        })
        console.log('DROPDOWNS', arr)
        return arr
      },
      existingInsuranceTextboxes () {
        let arr = []
        this.stateData[0].existingInsurance.forEach(item => {
          if (item.type === 'Textbox') {
            arr.push(item)
          }
        })
        console.log('TEXTBOXES', arr)
        return arr
      },
      existingInsuranceRadioButtons () {
        let arr = []
        this.stateData[0].existingInsurance.forEach(item => {
          if (item.type === 'Radio') {
            arr.push(item)
          }
        })
        console.log('RADIOBUTTONS', arr)
        return arr
      },
      existingInsuranceDates () {
        let arr = []
        this.stateData[0].existingInsurance.forEach(item => {
          if (item.type === 'Date') {
            arr.push(item)
          }
        })
        console.log('DATES', arr)
        return arr
      },
      existingInsuranceAddresses () {
        let arr = []
        this.stateData[0].existingInsurance.forEach(item => {
          if (item.type === 'Address') {
            arr.push(item)
          }
        })
        console.log('ADDRESSES', arr)
        return arr
      },
      CAReplacementData () {
        let data = this.stateData.filter(item => item.state === 'CA')
        data = data[0]
        return data
      },
      hasExistingInsurance () {
        if (this.currentLifeInsurance === 'true' || this.existingCompanyInsurance === 'true') {
          return true
        } else {
          return false
        }
      }
    },
    watch: {
      loading (val) {
        console.log('watch loading change is ', this.loading)
      },
      menu (val) {
        val && setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR'
          this.pickerDate = null
        })
      },
      providerloading (val) {
        if (!val) return

        setTimeout(() => (this.providerloading = false), 2000)
      },
      sitename: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    created () {
      this.quotekey = this.$route.params.key
      this.currentuser = this.$store.getters.user
      this.appQuestions = this.$store.getters.getSymetraAppQuestions
      let rec = {
        quotekey: this.quotekey,
        user: this.user
      }
      this.$store.dispatch('beginSymetraDocumentProcess')
      this.$store.dispatch('loadSymetraNotices')
      this.$store.dispatch('symetraLoadFollowUps')
      this.$store.dispatch('loadquote', rec)
      // this.$store.dispatch('loadSymetra_ICC20LA1', { userkey: '-MmPO971RQUYKf-p8A2_' })
      // this.$store.dispatch('loadSymetra_ICC20LA2', { userkey: '-MmPO971RQUYKf-p8A2_' })
      // this.$store.dispatch('loadSymetra_ICC20LA3', { userkey: '-MmPO971RQUYKf-p8A2_' })
      // this.$store.dispatch('loadSymetra_LU820', { userkey: '-MmPO971RQUYKf-p8A2_' })
      this.$store.dispatch('symetraWOPRates', { faceamount: this.$store.getters.getcurrentquote.policyvalue, nearestage: this.nearestAge, termlength: this.$store.getters.getcurrentquote.termlength })

      this.$store.dispatch('checkpostcode', this.currentuser.postcode)
      this.$gtag.pageview('SymetraTermLifeApplication')
      this.$gtag.event('symetraLifeApplicattionStarted')

      this.appQuestions.forEach(entry => {
        Object.assign(entry, { useranswer: '' })
      })
      // nearestAge () {
      //   let birthdate = moment(this.currentuser.byear + '-' + this.currentuser.bmonth + '-' + this.currentuser.bday).format('YYYY-MM-DD')
      //   const age = moment().diff(birthdate, 'years')
      //   let year = moment(birthdate).format('YYYY')
      //   let month = moment(birthdate).format('MM')
      //   let day = moment(birthdate).format('DD')
      //   let nearestAge = age
      //   let currentyear = moment().format('YYYY')
      //   let eventdate = moment(currentyear + '-' + month + '-' + day).add(6, 'months')
      //   let todaysdate = moment().format('YYYY-MM-DD')
      //   let daystonextbday = moment(eventdate).diff(todaysdate, 'days')
      //   console.log('event date ', eventdate.format('YYYY-MM-DD'), ' days to next bday ', daystonextbday)
      //   if (daystonextbday < 183) {
      //     return age + 1
      //   } return nearestAge
      // },
      // let bday = this.$store.getters.getapplieddetails.bday

      if (this.$store.getters.user.dob) {
        this.currentuser.byear = moment(this.$store.getters.user.dob).format('YYYY')
        this.currentuser.bmonth = moment(this.$store.getters.user.dob).format('MMM').toUpperCase()
        this.currentuser.bday = moment(this.$store.getters.user.dob).format('DD')
      }
      let keys = Object.keys(this.HEALTH_INTERVIEW.labelkey)

      console.log('starting health interview ', keys, this.HEALTH_INTERVIEW.labelkey)
      this.resetInterview()
    },
    // <---------------------------------METHODS--------------------------------->
    methods: {
      kickoutToAgent () {
        this.$router.push({ path: '/SurveySpecialHandling' })
      },
      splitEquallyCB () {
        if (this.shareEquallyCB === true) {
          this.percentageErrorCB = false
          if (this.numberOfCB === 3) {
            this.contingentBeneficiaries[0].percentageToRecieve = Math.ceil(100 / 3)
            this.contingentBeneficiaries[1].percentageToRecieve = Math.floor(100 / 3)
            this.contingentBeneficiaries[2].percentageToRecieve = Math.floor(100 / 3)
          } else {
            for (let i = 0; i < this.numberOfCB; i++) {
              this.contingentBeneficiaries[i].percentageToRecieve = Math.floor(100 / this.numberOfCB)
            }
          }
        }
      },
      splitEquallyPB () {
        if (this.shareEquallyPB === true) {
          this.percentageErrorPB = false
          if (this.numberOfPB === 3) {
            this.primaryBeneficiaries[0].percentageToRecieve = Math.ceil(100 / 3)
            this.primaryBeneficiaries[1].percentageToRecieve = Math.floor(100 / 3)
            this.primaryBeneficiaries[2].percentageToRecieve = Math.floor(100 / 3)
          } else {
            for (let i = 0; i < this.numberOfPB; i++) {
              this.primaryBeneficiaries[i].percentageToRecieve = Math.floor(100 / this.numberOfPB)
            }
          }
        }
      },
      sameAddressPB (index) {
        if (this.primaryBeneficiaries[index].sameAddress === true) {
          this.primaryBeneficiaries[index].individual.residentialAddress.addressLine1 = this.currentuser.address
          this.primaryBeneficiaries[index].individual.residentialAddress.city = this.currentuser.city
          this.primaryBeneficiaries[index].individual.residentialAddress.state = this.currentuser.state
          this.primaryBeneficiaries[index].individual.residentialAddress.zip = this.currentuser.postcode
        } else {
          this.primaryBeneficiaries[index].individual.residentialAddress.addressLine1 = ''
          this.primaryBeneficiaries[index].individual.residentialAddress.city = ''
          this.primaryBeneficiaries[index].individual.residentialAddress.state = ''
          this.primaryBeneficiaries[index].individual.residentialAddress.zip = ''
        }
      },
      sameAddressCB (index) {
        if (this.contingentBeneficiaries[index].sameAddress === true) {
          this.contingentBeneficiaries[index].individual.residentialAddress.addressLine1 = this.currentuser.address
          this.contingentBeneficiaries[index].individual.residentialAddress.city = this.currentuser.city
          this.contingentBeneficiaries[index].individual.residentialAddress.state = this.currentuser.state
          this.contingentBeneficiaries[index].individual.residentialAddress.zip = this.currentuser.postcode
        } else {
          this.contingentBeneficiaries[index].individual.residentialAddress.addressLine1 = ''
          this.contingentBeneficiaries[index].individual.residentialAddress.city = ''
          this.contingentBeneficiaries[index].individual.residentialAddress.state = ''
          this.contingentBeneficiaries[index].individual.residentialAddress.zip = ''
        }
      },
      calcPBPercentage () {
        let sum = 0
        this.primaryBeneficiaries.forEach(item => {
          if (item.percentageToRecieve !== '') {
            sum += Number(item.percentageToRecieve)
            console.log(sum)
          } if (sum === 100) {
            this.percentageErrorPB = false
          } else this.percentageErrorPB = true
        })
      },
      calcCBPercentage () {
        let sum = 0
        this.contingentBeneficiaries.forEach(item => {
          if (item.percentageToRecieve !== '') {
            sum += Number(item.percentageToRecieve)
            console.log(sum)
          } if (sum === 100) {
            this.percentageErrorCB = false
          } else this.percentageErrorCB = true
        })
      },
      setAmountsPB () {
        this.shareEquallyPB = false
        let num = this.numberOfPB
        this.primaryBeneficiaries.forEach(item => item.percentageToRecieve = '')
        if (num === 1) {
          this.primaryBeneficiaries[0].percentageToRecieve = 100
        }
      },
      setAmountsCB () {
        this.shareEquallyCB = false
        let num = this.numberOfCB
        this.contingentBeneficiaries.forEach(item => item.percentageToRecieve = '')
        if (num === 1) {
          this.contingentBeneficiaries[0].percentageToRecieve = 100
        }
      },
      hobbyCheck () {
        console.log(this.hobbies)
        if (this.hobbies.includes('None of the above')) {
          this.hobbies = ['None of the above']
        }
      },
      resetInterview () {
        this.HEALTH_INTERVIEW.labelkey.blood = ''
        this.HEALTH_INTERVIEW.labelkey.bone = 'true'
        this.HEALTH_INTERVIEW.labelkey.brain = ''
        this.HEALTH_INTERVIEW.labelkey['breast kidney'] = ''
        this.HEALTH_INTERVIEW.labelkey.cancer = ''
        this.HEALTH_INTERVIEW.labelkey.crime = ''
        this.HEALTH_INTERVIEW.labelkey.diabetes = ''
        this.HEALTH_INTERVIEW.labelkey.doctor = ''
        this.HEALTH_INTERVIEW.labelkey['drug use'] = ''
        this.HEALTH_INTERVIEW.labelkey.dui = ''
        this.HEALTH_INTERVIEW.labelkey.fly = ''
        this.HEALTH_INTERVIEW.labelkey.heart = ''
        this.HEALTH_INTERVIEW.labelkey.history = ''
        this.HEALTH_INTERVIEW.labelkey.hiv = ''
        this.HEALTH_INTERVIEW.labelkey.lung = ''
        this.HEALTH_INTERVIEW.labelkey.mental = ''
        this.HEALTH_INTERVIEW.labelkey.prescription = ''
        this.HEALTH_INTERVIEW.labelkey.primarydoctor_yn = ''
        this.HEALTH_INTERVIEW.labelkey.rehab = ''
        this.HEALTH_INTERVIEW.labelkey.stomach = ''
        this.HEALTH_INTERVIEW.labelkey.surgery = ''
        this.HEALTH_INTERVIEW.labelkey['weight change'] = ''
      },
      calculateADBprices () {
        this.$store.dispatch('symetraADBRates', { faceamount: this.adbAmount, nearestage: this.nearestAge, gender: this.currentuser.gender })
      },
      formatDobPB (index) {
        let person = this.primaryBeneficiaries[index].individual
        if (person.bDay && person.bMonth && person.bYear) {
          let dob = moment(person.bMonth + '/' + person.bDay + '/' + person.bYear).format('MM/DD/YYYY')
          console.log('DOB', dob)
          person.dateOfBirth = dob
          console.log(person)
          return dob
        }
      },
      formatDobCB (index) {
        let person = this.contingentBeneficiaries[index].individual
        if (person.bDay && person.bMonth && person.bYear) {
          let dob = moment(person.bMonth + '/' + person.bDay + '/' + person.bYear).format('MM/DD/YYYY')
          console.log('DOB', dob)
          person.dateOfBirth = dob
          console.log(person)
          return dob
        }
      },
      logData () {
        console.log('Current User:', this.currentuser)
        console.log('Primary Beneficiaries:', this.primaryBeneficiaries)
        console.log('Contingent Beneficiaries:', this.contingentBeneficiaries)
      },
      getCrRates () {
        this.$store.dispatch('symetraICBRates', this.faceAmountArray)
      },
      getCrFutureRate () {
        this.$store.dispatch('symetraICBRates', [{ faceamount: this.ctrFuture_amount }])
      },
      kickoutOrContinue () {
        if (this.hasExistingInsurance) {
          this.$router.push({ path: '/SurveySpecialHandling' })
        } else {
          this.nextquestion('applicant details')
          this.startLoading()
        }
      },
      calcChildAge (child) {
        this.childAgeError = false
        let day = child.bDay
        let month = child.bMonth
        let year = child.bYear
        if (day && month && year) {
          let dateofbirth = moment(year + '-' + month + '-' + day).format('YYYY-MM-DD')
          let age = moment().diff(dateofbirth, 'years')
          let days = moment().diff(dateofbirth, 'days')
          if (days < 15) {
            this.childAgeError = true
            child.bDay = ''
            child.bMonth = ''
            child.bYear = ''
          } else if (age > 17) {
            this.childAgeError = true
            child.bDay = ''
            child.bMonth = ''
            child.bYear = ''
          }
          return age
        } else {
          return '-'
        }
      },
      format_amt (val) {
        return numeral(val).format('$0,00')
      },
      startLoading () {
        this.element1Visible = true
        this.element3Visible = false
        setTimeout(() => this.element1Visible = false, 3000)
        setTimeout(() => this.element2Visible = true, 3500)
        setTimeout(() => this.element2Visible = false, 6500)
        setTimeout(() => this.element3Visible = true, 7000)
      },
      showFollowup (question) {
        question = true
      },
      hideFollowup (question) {
        question = false
      },
      getMailingAddressData (addressData, placeResultData, id) {
        this.docmailingAddress = addressData.name
        this.docmailingCity = addressData.locality
        this.docmailingZip = addressData.postal_code
        this.docmailingState = addressData.administrative_area_level_1
        this.healthcareprovider.address = addressData.name + ' ' + addressData.locality + ' ' + addressData.postal_code + ' ' + addressData.administrative_area_level_1
      },
      getResidentialAddressData (addressData, placeResultData, id) {
        this.currentuser.address = addressData.name
        this.currentuser.city = addressData.locality
        this.currentuser.postcode = addressData.postal_code
        this.currentuser.state = addressData.administrative_area_level_1
      },
      ackNotice (item) {
        console.log(' item ', item, ' ACK ')
        console.log('validdoccheck', this.validdoccheck)
        //         {
        //   name: 'HIPAA',
        //   url: '',
        //   ack: '',
        //   detail: 'The form authorizes any physician, hospital, pharmacy, pharmacy benefit manager, health insurance plan or any other entity that possesses any diagnosis, treatment, prescription or other medical information about me to furnish such health information to Symetra Life Insurance Company for the purpose of evaluating my eligibility for insurance. This medical or health information may include information on the diagnosis and treatment of mental illness, alcohol, and drug use. This also includes information on the diagnosis, treatment, and testing results related to HIV, AIDS, and sexually transmitted diseases, unless otherwise restricted by state law. Health information obtained will not be re-disclosed without my authorization unless permitted by law, in which case it may not be protected under federal privacy rules. This authorization shall be valid for two years from this date and may be revoked by sending written notice to Symetra Life Insurance Company.'
        // },
        // { name: 'eSignature & eDelivery Consent form', url: '', ack: '', detail: 'This form acknowledges your consent to transact business with Symetra Life Insurance Company and its affiliates and third-party service providers through electronic communications and, in some instances, using electronic signatures. You also consent to using eDelivery for any policy-related documents.' },
        // { name: 'Information around underwriting processes', url: '', ack: '', detail: 'This form highlights the information we collect to determine your eligibility for insurance based on our risk criteria, including (but not limited to): Your age, occupation, finances, physical condition, health history, mode of living, avocations, and other personal characteristics.' },
        // { name: 'Notice of Insurance Information Practices', url: '', ack: '', detail: 'This form authorizes Symetra Life Insurance Company to request medical, non-medical, employment and insurance information from outside parties, including (but not limited to): Medical care providers and/or physicians, health insurance record holders, motor vehicle departments, credit or consumer reporting agencies, etc.' },
        // { name: 'Authorization to release personal information', url: '', ack: '', detail: 'This form authorizes Symetra Life Insurance Company to request medical, non-medical, employment and insurance information from outside parties, including (but not limited to): Medical care providers and/or physicians, health insurance record holders, motor vehicle departments, credit or consumer reporting agencies, etc.' },
        // { name: 'Privacy Notice', url: '', ack: '', detail: 'This form explains how we use and protect your information. Symetra Life Insurance Company is serious about keeping your personal information private and secure. We do not sell or rent information about you to others.' },

        console.log(' item name ', item.name, item.ack)
        if (item.name === 'HIPAA') {
          this.hippaAck = !this.hippaAck
          console.log('set hippa')
        }
        if (item.name === 'eSignature & eDelivery Consent form') {
          this.eDeliveryAck = !this.eDeliveryAck
          console.log('set consent')
        }
        if (item.name === 'Information around underwriting processes') {
          this.uwProcessAck = !this.uwProcessAck
          console.log('set uw')
        }
        if (item.name === 'Authorization to release personal information') {
          this.infoRelAck = !this.infoRelAck
        }
        if (item.name === 'Notice of Insurance Information Practices') {
          this.noticeOfInsPracticesAck = !this.noticeOfInsPracticesAck
        }
        if (item.name === 'Privacy Notice') {
          this.privNoticeAck = !this.privNoticeAck
        }
        console.log(' notice list setting ', this.noticelist.map(entry => entry.ack))
      },
      createCaseId () {
        let rec = {
          quotekey: this.quotekey,
          userkey: this.currentuser.id
        }
        this.$store.dispatch('symetraCreateCaseId', rec)
      },
      sendInterview () {

      },
      createAppDocuments () {
        let rec = {
          quotekey: this.quotekey,
          currentuser: this.currentuser,
          healthinterview: this.hinterview,
          hobbies: this.hobbies,
          primaryBeneficiaries: this.primaryBeneficiaries,
          owner: this.owner,
          healthcareprovider: this.healthcareprovider,
          HEALTH_FOLLOWUP: this.HEALTH_FOLLOWUP,
          adb: this.adb,
          adbAmount: this.adbAmount,
          wop: this.wop,
          ctr: this.ctr,
          ctr_children: this.ctr_children
        }
        this.$store.dispatch('renderSymetraPDFRecords', rec)
      },
      createPolicy () {
        let rec = {
          quotekey: this.quotekey,
          userkey: this.currentuser.id,
          caseId: this.$store.getters.getcurrentquote.symetra.casedata.caseid,
          lifeId: this.$store.getters.getcurrentquote.symetra.lifeId
        }
        console.log(this.$store.getters.getcurrentquote.symetra.casedata)
        console.log('creating a policy ui', rec)
        this.$store.dispatch('symetraCreatePolicy', rec)
      },
      createTermLife () {
        // this gives them details about the person now they have a life id
        let rec = {
          quotekey: this.quotekey,
          userkey: this.currentuser.id,
          firstname: this.currentuser.firstname,
          lastname: this.currentuser.lastname,
          lifeId: this.$store.getters.getcurrentquote.symetra.lifeId,
          caseId: this.$store.getters.getcurrentquote.symetra.casedata.caseid,
        }
        console.log('creating a term life record id', rec)
        this.$store.dispatch('symetraCreateTermLife', rec)
      },
      sendTermLifeData () {

      },
      createTermLifeInterview () {

      },
      createLifeId () {
        console.log('post data ', this.$store.getters.postcodedata)
        let state = this.$store.getters.postcodedata.state_code
        let lifeId = {
          ssn: '123124444',
          firstName: this.currentuser.firstname,
          lastName: this.currentuser.firstname,
          birthDate: this.currentuser.dob,
          email: this.currentuser.email,
          phone: this.currentuser.mobile,
          address: {
            addressLine1: this.currentuser.address,
            addressLine2: '',
            city: this.currentuser.city,
            state: this.currentuser.state,
            zipCode: this.currentuser.postcode,
            country: 'US'
          }
        }
        let rec = {
          lifeId: lifeId,
          quotekey: this.quotekey,
          userkey: this.currentuser.id
        }
        this.$store.dispatch('symetraCreateLifeId', rec)
        console.table('life table id data ', lifeId, this.currentuser)
      },
      testusb () {
        console.log(this.appQuestions)
        this.$store.dispatch('symetraInterviewUpdate', { qId: 'LA2_ARMED_FORCES', caseId: '1234', answer: false })
      },
      season (val) {
        return this.icons[val]
      },
      getquestiontext (label) {
        let qdetail = ''
        qdetail = this.appQuestions.find(entry => entry.label === label)
        if (qdetail) {
          return qdetail.question
        }
        return 'unknown'
      },
      getMedicalFollowups (label) {
        let followup = ''
        followup = this.medicalFollowups.find(entry => entry.label === label)
        if (followup) {
          return followup.followups
        }
        return 'unknown'
      },
      getQuestionId (label) {
        let qid = ''
        qid = this.appQuestions.find(entry => entry.label === label)
        console.log(qid)
        if (qid) {
          return this.LIFE_INTERVIEW[qid.ApiId]
        }
        return 'unknown'
      },
      nextquestion (completedQuestion) {
        console.log('data ', this.HEALTH_INTERVIEW)
        this.$gtag.event('symetraLifeApplicattionAnswer')
        this.e1++
        // this.$gtag.event('Symetra Term Life Applicaton ', { question: completedQuestion, campaign: 'none' })
        console.log('step completed ', completedQuestion)
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      back () {
        this.e1--
        if (this.e1 < 1) {
          this.e1 = 1
        }
      },
      requestproviderdata () {

      },
      endtermsurvey () {
        this.monthlypremium = '$20.00'
        this.policyoptions.policyvalue = this.policyamounts[this.policyvalue]
        this.policyoptions.termlength = this.spans[this.termlength]
        this.policyoptions.provider = this.provider[0]
        console.log('policy options ', this.policyoptions)
        this.$store.dispatch('createtermlifepolicy', this.policyoptions)
        this.$gtag.event('symetraLifeApplicattionEnded')
        this.$router.push({ path: '/' })
      },
      termicon (val) {
        return this.termicons[val]
      },
      getpostcodedata (postcode) {
        this.$store.dispatch('checkpostcode', postcode)
        this.e6 = '2'
      },
      selectcoverage () {
        console.log('pushing to new view')
        this.$router.push({ path: '/TermCoverage' })
      },
      save (date) {
        this.$refs.menu.save(date)
      },
      validateField () {
        this.$refs.form1.validate()
      },
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      resetTop () {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      validateForm (scope) {
        return this.$validator.validateAll(scope)
      }
    }
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 157, 179, 1);
}
.coverage-large {
    font-size: 30px;
    display: block;
    text-align: center;
    font-weight: 500;
    line-height: 34px;
    margin-top: 50px;
}
.total-calculate{
  display: flex;
  justify-content: center!important;flex-wrap:wrap; border:1px solid #ccc; padding: 5px 20px; max-width: 450px; margin: 0 auto; border-radius: 20px;}

.total-calculateh3{display: block; width:100%; text-align: center; border:1px solid #ccc; padding: 5px 20px; font-size:42px; color:#129fb1; font-family: 'formadjrdeckbold'; border-radius: 20px;}
.total-calculatep{display: block; width:100%; text-align: center; font-size:14px; margin: 0;}

.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
 .z-btn {
   display: inline-block;
   height: 54px;
   line-height: 48px;
   border: 2px solid #009db3;
   background: #009db3;
   color: #ffffff;
   padding: 0 30px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   border-radius: 10px;
   font-weight: 700;
   text-transform: capitalize;
   min-width: 160px;
   text-align: center;
 }

 .bordered {
    border: 2px solid #009db3;
    border-radius: 10px;
 }
</style>
<style lang="sass">

  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
